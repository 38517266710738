.modal-content{
  width: 1200px;
}
.commnets{
	display: flex;
	justify-content: flex-end;
}
.commnets span{
	color:#245983;
}
.commnets span:hover{
	text-decoration: underline;
	cursor: pointer;
}

.commentBox{
	max-height: 240px;
}

.commentText::first-letter {
    text-transform: uppercase;
}

.personal_image img{
	width: 100%;
  cursor: pointer;
}

.comment_loader{
    z-index: 100;
    position: absolute;
    left: 50%;
    top: 30%;
}

.ReactModal__Overlay{
  z-index: 10000!important;
}

@media(max-width:767px){
    .detailLead{
       max-height: none;
    }
}    