.UpdateCp{
	 top: 5em !important;
}

@media(max-width:767px){
    .UpdateCp {
	    top: 1em !important;
	    right: 2.8em!important;
	}
	.cpDelete{
	    top: 1em !important;
	    right: 5em!important;
	}
}    
