.content-wrapper {
    /*background: #fff;*/
    /*padding-bottom: 60px;*/
    min-height: 750px;
}
.content-header {
    padding: 15px .5rem;
}
.breadcrumb{
	padding: 0px!important;
}

.magnetShort{
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.header_title{
	font-size: 1.4rem;
	font-family: inherit;
    font-weight: 400;
    line-height: 1.2;
}
.small-box {
    border-radius: .8rem;
    box-shadow: 0 0 1px rgba(0,0,0,.125), 0 1px 3px rgba(0,0,0,.2);
    display: block;
    margin-bottom: 20px;
    position: relative;
}
.small-box>.inner {
    padding: 10px;
}
@media (min-width: 1200px){
	.col-lg-3 .small-box h3, .col-md-3 .small-box h3, .col-xl-3 .small-box h3 {
	    font-size: 2.2rem;
	}
}
.small-box h3, .small-box p {
    z-index: 5;
}
.small-box h3 {
    font-size: 2.2rem;
    font-weight: 700;
    margin: 0 0 10px 0;
    padding: 0;
    white-space: nowrap;
}
.inner{
	color: #fff;
}
.small-box p {
    font-size: 1rem;
}
.small-box .icon {
    color: rgba(0,0,0,.15);
    z-index: 0;
}
.small-box .icon>.dash_card_icon {
    font-size: 70px;
    top: 20px;
}
.small-box .icon>.dash_card_icon {
    font-size: 90px;
    position: absolute;
    right: 15px;
    top: 15px;
    transition: all .3s linear;
}
.small-box>.small-box-footer {
    background: rgba(0,0,0,.1);
    color: rgba(255,255,255,.8);
    display: block;
    padding: 3px 0;
    position: relative;
    text-align: center;
    text-decoration: none;
    z-index: 10;
}

.desk_head{
    margin-bottom: 0.5rem;
    /*text-align: center;*/
    /*text-transform: uppercase;*/
    padding-top: .5rem;
    padding-bottom: .5rem;
    /*font-weight: 600;*/
    color: #0E3746!important;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 22px;
}
.desk_count, .dashboard_label{
    /*margin-bottom: 0.5rem;*/
    /*text-align: center;*/
    /*text-transform: uppercase;*/
    padding-top: .5rem;
    /*padding-bottom: .5rem;*/
    font-weight: 500;
    color: #41A9C1!important;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 22px;
}
.RatioCard{
    height: 135px;
}
.dashboard_label{
    font-size: 20px;
}

.customTooltip{ 
    text-transform: capitalize;
    padding: 8px 0px;
    color: lightblue;
    font-size: 16px;
}
.fontColor{ 
   color: #48a9c1;
}

/*==============================New freelancer css ===========================*/
.custom_button {
  padding: 5px 20px;
  border-radius: 0.25rem;
  font-size: 12px;
  transition: all 0.3s ease-in-out;
  color: #fff;
  background-color: #103746;
  border-color: #103746;
  width: 70%
}

.tab-content {
    padding: 35px 30px;
    background: #fff;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.05);
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    border: none;
    color: #111;
    box-shadow: 0 -5px 10px 0 rgba(0, 0, 0, 0.05);
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff !important;
    border-color: #dee2e6 #dee2e6 #fff;
}

.tab_text_head{
  color: #17839d;
  font-size: 15px;
}

.tab_text_sub_head{
  color: #0e3746;
  font-size: 16px;
}

.path-16 {
  object-fit: contain;
  border: solid 1px #e4e4e4;
  background-color: #fbfbfb;
}
.left_border {
  border-left: 1px solid #eaeaea;
}   
.table_margin{
  padding:13px 0; margin-right:40px;
}
.spends_value {
  font-size: 25px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.24;
  letter-spacing: normal;
  color: #41a9c1;
}
.spends_text {
  font-size: 23px;
  color: #000;
}

.progress-bar{
    background-color: #41A9C1 !important;
    /*box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.3);*/
}
.progressCount{
    position: absolute;
    right: 4px;
    color: #000;
}

.popup_hover {

  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}
.popup_hover:hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)
}

/*.progress-bar.progress-c-theme {
    background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
}*/
.leadsBlock{
    font-size: 14px;
    color: #888;
    font-weight: 400;
}
.dot {
  height: 10px;
  width: 10px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
  margin-left: 10px;
}

.open_leads_tags {
  font-size: 12px;
  color: #000;
}
.card_font_25 {
  font-size: 25px;
  color: #000;
}
.card_font_20 {
    font-size: 20px;
    color: #000;
  }
.tab_scroll {
    overflow-y: auto;
    max-height: 620px;
}

.progress {
    height: 12px;
    font-size: 10px;
    background-color: #f4f7fa !important;
    border-radius: 0;
    overflow: visible;
}

.openCount{
    text-align: center;
}

/*.custom_button_nav_right1 {
  padding: 5px;
  border-radius: 0.25rem;
  font-size: 12px;
  transition: all 0.3s ease-in-out;
  color: #fff;
  background-color: #103746;
  border-color: #103746;
  width: 120px;
  line-height: 1.5;
  margin-right: 10px;
}

.custom_button_nav_right2 {
  padding: 5px;
  border-radius: 0.25rem;
  font-size: 12px;
  transition: all 0.3s ease-in-out;
  color: #0e3746;
  background-color: transparent;
  border-color: #103746;
  width: 120px;
  line-height: 1.5;
  margin-right: 10px;
}   */


/*==============================New freelancer css ===========================*/

@media only screen and (max-width: 991px){

    .small-box p {
        font-size: 12px;
    }
    .small-box h3 {
        font-size: 1.8rem;
    }
    .magnetShort{
        padding-right: 15px !important;
       padding-left: 15px !important;
    }
    .m-b-3{
        margin-bottom: 1em !important;
    }
    .tab-content {
        padding: 12px 6px;
    }
    .custom_button {
        width:90%;
    }
    .openCount{
        text-align: right;
    }
    .dashboard_label, .customTooltip{
        font-size: 16px;
    }
    .customTooltip{ 
        padding: 3px 0px;
    }
}