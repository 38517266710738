@import "../sass_config/theme.sass";

.SocialBlockGroup {
	background-color: get-color('primary', 'contrastText');
	box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.05);

	&, &-Menu {
		.icon-ph {
			&.active {
				color: get-color('primary', 'contrastText');
				background-color: get-color('primary') !important; }

			&.whatsapp-icon {
				color: #fff;
				background-color: #1E7E34;

				&.active {
					background-color: #1E7E34 !important;
					color: #fff; } }

			&.call {
				color: #fff;
				background-color: #3F51B5;

				&.active {
					background-color: #3F51B5 !important;
					color: #fff; } }

			&.mail {
				color: #fff;
				background-color: #17A2B8;

				&.active {
					background-color: #3F51B5 !important;
					color: #fff; } }

			&.assign {
				color: #fff;
				background-color: #CF7500;

				&.active {
					background-color: #CF7500 !important;
					color: #fff; } }

			&.m-call {
				color: #fff;
				background-color: #212646;

				&.active {
					background-color: #212646 !important;
					color: #fff; } } } }



	@include respond-up-to('sm') {
		background-color: transparent;
		box-shadow: none;

		.corner-button {
			background-color: #049B5A !important;
			color: #fff;
			box-shadow: 5px 5px 15px rgba(0,0,0, 0.05); } } }
