.CallRecordBlock {
	padding-top: 16px;
	background-color: #fff;
	box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.05);

	.date {
		position: absolute;
		margin-top: -16px;
		left: 0px;
		width: 100%;
		display: flex;
		justify-content: center;

		.MuiChip-root {
			padding: 8px 16px;
			border-radius: 4px;

			.MuiChip-label {
				white-space: pre-wrap; } } }

	.no-edit {
		pointer-events: none; }

	.MuiCard-root {
		box-shadow: none;

		.MuiChip-root {
			margin-top: -16px; } }



	.MuiCardHeader-content {
		margin-top: 8px;
		display: flex;
		justify-content: space-between;
		color: #fff; }

	.listen {
		border-radius: 4px;
		padding: 4px 8px;
		cursor: pointer;
		background-color: #0E3746;
		color: #fff; }

	audio {
		width: 100%; } }
