/*.W-100{width: 100%; float: left;}
.MuiFormControl-root.MuiTextField-root {float: left;width: 100%;}
.BorderBottom{border-bottom:1.5px solid rgba(118, 118, 118,0.6);}
.PB-2{    padding-bottom: 0.7rem !important;}
.Astrik{color: #ff0000;}
.AddLead .MuiCard-root{padding:5%; margin-top: 20px;}*/

.registerPage{
	width: 70%;
}

.registerLogo img{
	width:70%;
}

.MuiFormControlLabel-root {
    float: left;
}
.MuiFormLabel-root {
    text-align: left;
}
.register-page {
	display: flex;
    /*justify-content: center;*/
    background-color: #fff;
}

@media(max-width:767px){
    .registerPage{
		width: 100%;
	}
	.login-page{
		height: auto !important;
	}
	.login-page .registerPage{
		padding: 0px !important;
	}
	.MuiStepper-root{
		padding-right: 0px !important;
		padding-left: 0px !important;
	}
} 