@import "../sass_config/theme.sass";

.ProfileBox {
	position: relative;
	.project-name {
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden; }
	.MuiCard-root {
		box-shadow: none; }
	.avatar {
		width: get-spacing(9);
		height: get-spacing(9);
		margin: 0 auto;
		left: 0;
		right: 0; } }
