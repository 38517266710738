
  .rectangle {
    width: 160px;
    height: 37px;
    box-shadow: 0px 0px 1px 1px #0000001a;
  }
  
  .pulse {
    animation: pulse-animation 2s infinite;
  }
  
  @keyframes pulse-animation {
    0% {
      box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
    }
    100% {
      box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
  }
  @media (max-width:768px){
    .rectangle {
      width: 115px;
    }
  }
  