.hrHeader{
	font-size: 1.65rem;
	line-height: 22px;
}
.MainContainer{
	width: 100%;
	float: left;
	background-color: #fff;
}
.holderContainer{
	padding-top: 58px;
	padding-bottom: 58px;
}
.uploadHeader {
    position: absolute;
    top: 156px;
    padding: 6px;
    left: 300px;
    border: 1px solid #000;
    border-radius: 10px;
    z-index: 1;
    font-size: 14px;
    line-height: 16px;
    background-color: #fff;
}
.tableHead{
    border: 1px solid #d3d3d3;
    box-shadow: 5px 5px 5px rgb(68 68 68 / 30%);
}
.docsRow{
    border-bottom: 1px solid #d2d2d2;
}
.formContainer{
	border: 1px solid #000;
    padding: 57px 26px 20px;
}
.input-container,.doc-name{
	display: flex;
}
.fileNameInput{
	width: calc(65% - 5px);
} 
.fileName{
	width: calc(35% - 5px);
	font-size: 14px;
	line-height: 46px;
}
.serial-num{
	margin-right: 1rem;
}
.divider {
    border: 1px solid #000;
    margin-bottom: 2rem;
}

.showDocIcon{
	font-size: 20px;
    color: #800000;
    font-weight: bolder;
    border: 2px solid #800000;
    border-radius: 50%;
    cursor: pointer;
    padding: 0px 3px;
    float: right;
}
.noDoc{
    font-style: italic;
}
.HandBook{
    width: 100%;
	height: 70vh;
	border: 1px solid lightgray
}
@media (max-width: 991px){
    .uploadHeader{
        left: 34px;
    }
    .hrDocSubmit{
        margin-top: 1.5rem;
    }
    .docsRow{
        position: relative;
    }
    .docsRow .listOptionBtn{
        position: absolute;
        right: 0px;
    }
    #simple-menu .singleMenu{
        min-height: 34px;
        padding-left: 10px;
        padding-right: 10px;
        font-size: 12px;
    }
    .singleMenu svg{
        font-size: 18px;
        margin-right: 4px;
    }
}
@media (max-width: 450px){
    .HandBook {
        height: 250px;
    }
}