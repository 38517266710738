.TextField {
	width: 100%;
	display: inline-flex;

	&.no-edit {
		pointer-events: none; } }

.Dropdown {
	width: 100%;

	&.no-edit {
		pointer-events: none; } }

