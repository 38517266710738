.pdfPreviewSection{
  width: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
  display: inline-block;
  font-family: "Nunito",sans-serif !important;}

.PdfPreiview {
  max-width:7in;
  display: inline-block;
  overflow: hidden;
  font-size: 62.5%;
 
}
.PdfPreiview.Max {
  max-width:100% !important;
  display: inline-block;
  overflow: hidden;
  font-size: 62.5%;
 
}

.BrandLogo {
  width: 100%;
  display: inline-block;
  margin: 10px 0;
  max-width: 200px;
}
.TextRight {
  text-align: right;
}
.FactBanner {
  margin: 30px 0;
  position: relative;
  width: 100%;
  display: inline-block;
}
.FactBanner img {
  width: 100%;
  max-width: 100%;
}
.ProjectName {
  position: absolute;
  bottom: 10%;
  right: 10%;
  color: #fff;
  text-align: right;
}

.ProjectName h1 {
  font-weight: 700;
  font-size:3em;
  text-shadow: 1px 1px 1px #000;
  color: #fff;
}
.HeadingTitle {
  font-size:2.2em;
  font-weight: 800;
  margin-bottom: 30px;
  display: inline-block;
}
.ProjectHeightImage:before {
  position: absolute;
  content: "";
  width: 150px;
  background: url("https://refrance.s3.ap-south-1.amazonaws.com/homesfy/AfterStyle.png");
  height: 150px;
  background-size: contain;
  right: -70px;
  top: 10%;
  background-repeat: no-repeat;
  transform: rotate(-4deg);
}

.FactCard {
  border-color: #f59a00 !important;
  margin-bottom: 20px;
  border-radius: 10px !important;
}
.FactCard .MuiCardContent-root {
  text-align: center;
  box-sizing: border-box;
  padding: 10px !important;
}
.FactCard h4 {
  font-weight: 800;
  font-size: 1.4em;
  margin: 0 0 5px 0;
}
.FactCard h6{margin: 0;font-size: 1.2em;}
.PriceTable thead th {
  color: #f59a00;
  border-bottom-color: #f59a00 !important;
  border-right: 1px solid #f59a00;
}

.PriceTable thead th:last-child {
  border-right: 0;
}

.PriceTable {
  border: 1px solid #f59a00 !important;
  border-radius: 10px !important;
}

.PriceTable tbody th,
.PriceTable tbody td {
  border-right: 1px solid #f59a00;
  border-bottom-color: #f59a00 !important;
}
.GallerySection img {
  border: 1px solid #e2e2e2;
  width: 100%;
}
.PaddingRight0 {
  padding-right: 0 !important;
}
.GallerySection li {
  width: 32%;
  display: inline-block;
  margin-right: 2%;
  padding: 0;
  margin-bottom: 2%;
}

.GallerySection li:nth-child(3n + 3) {
  margin-right: 0;
}
.AmenitiesSection li {
  position: relative;
  width: 50%;
  display: inline-block;
  padding-left: 30px;
  box-sizing: border-box;
  font-size: 1.4em;
  line-height: 20px;
}
.AmenitiesSection li.PDFList{padding-left:20px;}
.AmenitiesSection li:before {
  content: "";
  position: absolute;
  left: 0;
  border-left: 13px solid #f59a00;
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  top: 11px;
}
.AmenitiesSection li.PDFList:before {
  content: "";
  position: absolute;
  left: 0;
  border-left: 6px solid #f59a00;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  top: 11px;
}
.LocationTitle {
  line-height: 20px;
  font-size: 16px;
  text-transform: capitalize
}
.LocationContent {
  line-height: 20px;
  width: 100%;
  display: inline-block;
  font-size:1.4em;
}
.Title {
  font-weight: 800;
  font-size: 10pt;
  text-transform: capitalize;
}

.Content {
  font-size:8pt;
}
.pdfPreviewSection .Overview{
  font-size: 14px;
}
.Disclaimer{
  font-size: 10px;
}
@media (max-width:991px) {
  .ProjectHeightImage:before{    
    width: 80px;
    right:0;
    top: auto;
    bottom: 0;
    height: 72px;
  }
  .GallerySection li{
    width: 50%;
    margin: 0;
    
  }
  .AmenitiesSection li{width:100%;}
  .LocationContent {
    margin-bottom: 20px;
}
}