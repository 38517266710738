.LeaderBoardSection{
    width: 100%;
    background-color: #fff;
    border:3px #fdfdfd solid;
    margin-bottom: 20px;
    /* box-shadow: 0 14px 28px rgb(0 0 0 / 25%), 0 10px 10px rgb(0 0 0 / 22%); */
    border-radius: 4px;
}
.LeaderBoardSection .LeaderTop{
    background-color: #0E3746;
    padding: 1.5em;
    border-radius: 4px;
}
.LeaderHead{
    margin-bottom: 20px;
}
.LeaderTitle,.AwardTitle, .Name{
    color: #fff;
}
.Name{
    position: relative; 
}
.Name:after {
    position: absolute;
    content: '';
    height: 1px;
    bottom: -4px;   
    opacity: .4; 
    margin: 0 auto;
    left: 0;
    right: 0;
    width: 50%;
    background: #fff;
}

.Name{
    font-weight: bold;
}
.LeaderTitle{
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 10px;
}
.Winner{
    text-align: center;
}
.AwardImg{
    border-radius: 50%;
    border: 3px solid #fff; 
    margin-bottom: 20px;
    width: 150px;
    height: 150px;
    margin: 0 auto 18px;
}
.AwardeeImg{
    position: relative;
}
.AwardIcon{
    position: absolute;
    bottom: -10px;
    margin: auto;
    left: 0;
    right: 0;
}
 
.LeaderBoardSection .slick-dots {
    margin-bottom: 5px;
  }
  .LeaderBoardSection .slick-dots li button:before {
    font-size: 12px !important;
    /* color: #0E3746 !important; */
    opacity: 1 !important;
  }
  .LeaderBoardSection .slick-dots li.slick-active button:before{
    font-size: 20px !important;
    color: #fff !important;
  }