.MainSection{
  font-family: 'Poppins', sans-serif;
  background: #fff;
}
.MainSection *{
  font-family: 'Poppins', sans-serif;
}
#bgImage {
    background-image: url(https://refrance.s3.ap-south-1.amazonaws.com/mymagnet/bg.svg);
    background-repeat: no-repeat;
    background-position:center;
    position: relative;
    width: 100%;
    height: 100vh;
  }
#bgBlob{
    background-image: url("https://refrance.s3.ap-south-1.amazonaws.com/mymagnet/blob.svg");
    background-repeat: no-repeat;
    position: relative;
    height: 100%;
}
.brandImg{
    height: 75px;
}
.w-90{
    width: 90% !important;
    margin: 0 auto !important;
}
.RegisterMsg{
    font-size: 1.35em;
}
 
.mgSlider .slick-dots {
  width: 5% !important;
  top: 20% !important;
  right: 0 !important;
  display: flex !important;
  flex-direction: column !important;
}
.mgSlider .slick-dots li button:before {
  font-size: 12px !important;
  color: #0E3746 !important;
  opacity: 1 !important;
}
.mgSlider .slick-dots li.slick-active button:before{
  font-size: 20px !important;
  color: #AF4063 !important;
}
.mgSlider {
  padding: 10px 30px;
}

.text-mg{
  color: #1A3460 !important;
}
.bg-mg{
  background: #1A3460 !important;
  color: #fff !important;
}
.header-mg{
  background: #E5E5E5 !important;
}

.headerLink{
  font-weight: 600 !important;
  font-size: 18px !important;
  text-transform: capitalize !important;
  padding: 0 5px;
  margin: 0;
  color: #1A3460 !important;
}
.slideImg{
  padding: 12% 10%;
  box-sizing: border-box;
  display: inline-block;
  width: 350px;
  height: 350px;
  overflow: hidden !important;
}

.menuIcon{
  position: absolute !important;
  right: 0 !important;
  color: #000 !important;
}
.whatsapp{height: 23px !important}
@media(max-width: 480px){
  .brandImg{height: 55px;}  
}