.bookings-container {
  padding: 1rem;

  .legend-text {
    font-size: 0.85rem;
  }

  .booking-list {
    .booking-item {
      &.pending-documents {
        border-left: 5px solid #000;
      }
      &.pending-confirmation {
        border-left: 5px solid #d3ff5b;
      }
      &.approved {
        border-left: 5px solid #25e004;
      }
      &.rejected {
        border-left: 5px solid #fb0c0c;
      }

      .text-muted {
        font-size: 12px;
      }

      .valueText {
        font-size: 14px;
      }

      .booking-action {
        cursor: pointer;
      }
    }
  }

  .row-class td {
    width: 160px !important;
  }
}

.fa-link {
  cursor: pointer;
}

.pending-documents {
  border-left: 5px solid #000 !important;
}
.pending-confirmation {
  border-left: 5px solid #d3ff5b !important;
}
.approved {
  border-left: 5px solid #25e004 !important;
}
.rejected {
  border-left: 5px solid #fb0c0c !important;
}
.lead-cancelled {
  color: red !important;
}

.blue-pill {
  padding: 0.2rem 1rem;
  background-color: #0704ff;
  color: #fff;
  border-radius: 20px;
  min-width: 70px;
}

.exeption-lable {
  margin-left: 7px;
  margin-top: 2px;
}

.bg-lead-cancelled {
  background-color: #ffbcad;
}