@import 'sass-material-colors';

$base-spacing: 8px;

$primary-color: 'blue-grey';
$secondary-color: 'purple';
$error-color: 'red';
$warning-color: 'orange';
$info-color: 'blue';
$success-color: 'green';

$primary: ("light": material-color($primary-color, '300'), "main": material-color($primary-color, '500'), "dark": material-color($primary-color, '700'), "contrastText": #fff);
$secondary: ("light": material-color($secondary-color, 'a200'), "main": material-color($secondary-color, 'a400'), "dark": material-color($secondary-color, 'a700'), "contrastText": rgba(0,0,0, 0.87));
$error: ("light": material-color($error-color, '300'), "main": material-color($error-color, '500'), "dark": material-color($error-color, '700'), "contrastText": #fff);
$warning: ("light": material-color($warning-color, '300'), "main": material-color($warning-color, '500'), "dark": material-color($warning-color, '700'), "contrastText": rgba(0,0,0,0.87));
$info: ("light": material-color($info-color, '300'), "main": material-color($info-color, '500'), "dark": material-color($info-color, '700'), "contrastText": rgba(0,0,0,0.87));
$success: ("light": material-color($success-color, '300'), "main": material-color($success-color, '500'), "dark": material-color($success-color, '700'), "contrastText": rgba(0,0,0,0.87));



@function get-spacing($base) {
	@return $base-spacing * $base; }

@function get-color($base:"primary", $varaint: "dark") {
	@if $base == "primary" {
		@return map-get($primary, $varaint); }
	@if $base == "secondary" {
		@return map-get($secondary, $varaint); }
	@if $base == "error" {
		@return map-get($error, $varaint); }
	@if $base == "warning" {
		@return map-get($warning, $varaint); }
	@if $base == "info" {
		@return map-get($info, $varaint); }
	@if $base == "success" {
		@return map-get($success, $varaint); } }


$breakpoints: ( 'xs':  0px, 'sm': 600px, 'md':  960px, 'lg': 1280px ,'xl': 1920px) !default;


@mixin respond-up-to($breakpoint) {
	@if map-has-key($breakpoints, $breakpoint) {
		// Prints a media query based on the value
		@media (max-width: map-get($breakpoints, $breakpoint)) {
	  		@content; } } }

@mixin respond-down-to($breakpoint) {
	@if map-has-key($breakpoints, $breakpoint) {
		// Prints a media query based on the value
		@media (min-width: map-get($breakpoints, $breakpoint)) {
	  		@content; } } }
