
.node circle {
  fill: rgb(247, 106, 214);
  stroke: rgb(184, 37, 81);
  stroke-width: 1.5px;
}

.node text {
  font-size: 14px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  background-color: black;
  fill: black;
}

.node {
  cursor: pointer;
}

path.link {
  fill: none;
  stroke: #2593B8;
  stroke-width: 1.5px;
}



.red {
  fill: none;
  stroke: red;
  stroke-width: 1.5px;
  stroke-dasharray: 5;
}

.yellow{
  fill: none;
  stroke: yellow;
  stroke-width: 1.5px;
}

.grey{
  fill: none;
  stroke: grey;
  stroke-width: 1.5px;
}

.black{
  fill: none;
  stroke: black;
  stroke-width: 1.5px;
}

.blue{
  fill: none;
  stroke: blue;
  stroke-width: 1.5px;
}

.green{
  fill: none;
  stroke: green;
  stroke-dasharray: 5;
  stroke-width: 1.5px;
}

.roleTree svg{
  width: 100%;
  height: 100%;
}