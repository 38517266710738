
/*Listing css start below*/
.listContainer{
    display: flex;
    flex-wrap: wrap;
}

.MainList {
    width: 100%;
    float: left;
    background: #f1f3f6;
}

.MainList .ListContent {
    width: 100%;
    float: left;
    padding: 20px;
    position:relative;
    min-height: 90px;
}
.MainList .ListContent:before {
    content: '';
    width: 4px;
    height: 100%;
    position: absolute;
    /* background-color: #f00; */
    left: -1px;
    top: 0;
}

.today-strip:before {
    background-color: #840072 !important;
}
.today-past-strip:before {
    background-color: #ffc107 !important;
}
.today-future-strip:before {
    background-color: #28a745 !important;
}
.past-strip:before {
    background-color: #dc3545 !important;
}
.future-strip:before {
    background-color: #343a40 !important;
}
.default-strip:before {
    background-color: #002984 !important;
}

.MainList .ListContent h6 {
    font-size: 12px;
}

.MainList .ListContent h3 {
    font-size: 12px;
}

.MainList .ListContent p {
    font-size: 12px;
}
.MainList i {
    float: left;
    margin-right: 5px;
    margin-top: 1px;
    color:#5296c1;
}
.MainList .ShortContent {
    width: 100%;
    /*padding: 20px 0px 0px;*/
    float: left;
    
}
.FollowupDate {
    float: right;

}
.Hide {
    display: none;
}
.MainList a {
    color: #5296c1;
}
.MainList .ShortContent i {
    margin-top: 6px;
}
/*Listing css start above*/

/*relevent css start below*/
.Action {
    padding: 2px 2px;
    background-color: #245983;
    border-radius: 50%;
    color: #fff;
    object-fit: contain;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
    width: 26px;
    /* position: absolute; */
    /* right: 1em; */
    /* top: 8px; */
    text-align: center;
    cursor: pointer;
}
.Action > svg {
    margin: 0;
    float: none;
    color: #fff;
}
.ActionSection {
    padding: 0px;
    width:100%;
    float:left;
    display:none;
}

.ActionSection a {
    display: table;
    margin: 0 auto;
    text-align: center;
    padding: 10px 40px;
    background-color: #5ca847;
    border-radius: 25px;
    
}

.ActionSection a svg {
    color: #fff;
    font-size: 20px;
    text-align: center;
    display: table;
    margin: 0;
}

a.fullDetail {
    position: absolute;
    right: 20%;
}

.fold-lead{
    text-align:left;
}

.pagination li{
    padding: 4px 16px;
    background-color: #fff;
    border: 1px solid #e8e3e3;
    border-radius: 2px; 
    cursor: pointer;
}
.pagination a{
    color:#333;
}
.paginationBlock{
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: 1em;
    margin-bottom: 1em;
}
.active{
    background-color: #f3f5f7!important;
}

/*.WhatsAppBG {
    top: 5em;
    background-color:#2FD356;
}*/
.bg-success{
   background-color:#2FD356!important; 
}
 button.bg-success:hover {
    background-color: #2FD356!important;
}
.MuiButton-containedSecondary {
    color: #fff;
    background-color: #2FD356;
}
.MuiButton-containedSecondary:hover {
    background-color: #2FD356;
}

.assign_rm svg {
    font-size: 30px !important;
    /*margin-right: 10px;*/
    cursor: pointer;
    right: 100px;
    top:0.5em;
}

.WhatsAppBG {
    /*top: 1.3em;*/
    /*width: 34px;*/
    /*height: 34px;*/
    /*margin: 0 auto;*/
    text-align: center;
    /*border: 1px solid #1e7e34;*/
    background-color: #1e7e34!important;
    /*border-radius: 50%;*/
}
.callBG{
    /*width: 34px;*/
    /*height: 34px;*/
    /*margin: 0 auto;*/
    text-align: center;
    top: 2.7em;
    /*border: 3px solid #3F51B5;*/
    background-color:#3F51B5!important;
    /*border-radius: 50%;*/
}
.deleteLead{
    top: 5em;
    background-color:#dc3545!important;
}

.revertLead{
    top: 5em;
    background-color:#0E3746!important;
}

.lead_check{
    /* position: absolute !important; */
    /* right: 8px; */
    /* top: 9em; */
    border: 3px solid #3F51B5;
    font-size: 20px;
}

  /*transition-duration: 0.5s*/
.WhatsAppBG .fa-whatsapp{
    padding-top: 2px;
    font-size: 20px;
    text-decoration: none;
    color: white;
}
.callBG .fa-phone-alt{
    /*padding-top: 3px;*/
    text-decoration: none;
    /*font-size: 22px!important;*/
    color: white;
}


.leadDetail{
    top: 9.4em;
}

.leadDetailBg{
    background-color:#0E3746!important;
} 
.leadInternalStatus{
    top: 11.4em;
}
.leadInternalStatusBg{
    background-color:#0E3746!important;
}
.CrossSale{
    background-color:#0E3746!important;
    top: 13.4em;
}
.ActionBg {
    background: -webkit-linear-gradient(50deg, #45cafc, #303f9f) !important;
    background: -o-linear-gradient(50deg, #45cafc, #303f9f);
    background: linear-gradient(40deg, #45cafc, #303f9f);
}

.ShortContent span{
    font-size: 12px;
}

.sortComment{
    max-height: 210px;
    width: 65%;
}
.commentAdd{
    width: 34%;
    float: right;
}
.inputComment{
    width: 90%;
}

.commentBtn{
    margin: 10px 0px !important;
}

.noComment{
    height: 100%;
}

.noComment span{
    top: 45%;
}

.leadMainHeader{
    position: -webkit-sticky;
    position: sticky;
    top: 4.2em;
    z-index: 100;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
}
.overDue{
    padding: 5px 10px;
}
.shortLeadComment{
    position: relative;
    justify-content: space-between;
}

.MuiFab-root{
    width : 35px!important ;
    height: 35px!important ;
}

.caller_lead {
    /* padding-left: 2.2em!important; */
    /* padding-right: 2.5em!important; */
    width: 120.21px;
}
svg.MuiSvgIcon-root.caller_icon {
    font-size: 2em;
}

@media(max-width:767px){
    .leadMainHeader{top: 3.55em;}
    .ActionSection{display:block;}
    .Action{margin-bottom: 12px;}
    .revertLead{right: 0.2em;}
        .MainList .ListContent{padding: 10px 10px;}
    .textRight{text-align:right;}
    .col-xs-6 {
        width: 50%;
    }

    .MainList .ShortContent{
        padding: 0px;
    }

    a.fullDetail {
        position: absolute;
        right: 10%;
    }

    .mobile_whatappLead{
        font-size: 18px;
    }
    .deleteLead{
        right: 2px;
    }
    .lead_check{
        /* top: 3.8em; */
        /* right: 0px; */
        padding-left: 4px!important;  
        padding-right: 4px!important;  
    }
    .leadDetail{
        right: 2px;
        top: 5.4em;
    }
    .leadInternalStatus{
        top: 7.8em;
        right: 2px;
    }
    .CrossSale{
        top: 10em;
        right: 2px;
    }
    .inputComment{
        width: 70%;
    }
    .commentBtn{
        top:10px;
    }

    .sortComment {
        width: 100%;
    }
}
.addLeadIcon{
    position: absolute;
    right: 3.5rem;
    font-size: 35px !important;
    top: 0.75rem;
    cursor: pointer;
}

.action_box{
    position: absolute;
    right: 10px;
    bottom: 10px;
    display: flex;
    width: 250px;
    justify-content: space-between;
    align-items: center;
}

.srch_action_box{
    position: absolute;
    right: 10px;
    bottom: 10px;
    display: flex;
    width: 80px;
    justify-content: space-between;
    align-items: center;
}

@media(max-width:767px){
    .fold-lead{
        text-align:left;
    }

    .action_box{
        right: 8px;
        bottom: unset;
        top: 12px;
        flex-direction: column;
        width: auto;
    }

    .viewMore{
        justify-content: space-between !important;
    }
} 