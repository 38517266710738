.login-page  {
    align-items: center;
    background: #e9ecef;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    height: 100vh;
    -ms-flex-pack: center;
    justify-content: center;
}
.login-card-body{
    background: #fff;
    border-top: 0;
    color: #666;
    padding: 20px;
}

.login-logo  {
	background-color: #fff;
    text-align: center;
}
.login-logo img {
	width: 270px;
    height: 60px;
}
.login-box-msg{
    margin: 0;
    padding: 0 20px 20px;
    text-align: center;
    font-family: Source Sans Pro;
}
.login-card-body .input-group .input-group-text .input-group .input-group-text {
    background-color: transparent;
    border-bottom-right-radius: .25rem;
    border-left: 0;
    border-top-right-radius: .25rem;
    color: #777;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

@media(max-width:767px){
    
    .login-page {
        background: #fff!important;
        position: relative;
        top: 4em;
    }
} 
