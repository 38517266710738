@import "../sass_config/theme.sass";

.NavigationButtonSidebarGroup {
	.drop-down {
		width: 100%; }

	.button-caption {
		text-transform: uppercase;
		font-size: 16px; }

	.card-button {
		cursor: pointer;
		box-shadow: none;
		border: 1px solid #000;
		border-radius: 5px;

		&.active {
			background-color: #049B5A !important;
			color: #fff;
			border: 1px solid transparent; }

		.MuiCardContent-root {
			padding-bottom: 16px; } }


	.stick-me {
		height: 56px;
		.dd-w {
			background-color: #fff;
			position: fixed;
			top: 0px;
			left: 32px;
			right: 32px; } } }
