.small_site_img{
    height: 55px;
    width: 72px!important;
    justify-content: center;
    align-items: center;
    padding: 0.5em!important;
}
.main-sidebar-mini{
    margin-left: 0;
    width: 4.6rem!important;
}
.main-sidebar.main-sidebar-mini:hover {
    width: 250px !important;
}
.main-sidebar{
    bottom: 0;
    float: none;
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
}


.main-sidebar.main-sidebar-mini .nav.nav-pills.nav-sidebar.flex-column > li >a>p, .main-sidebar.main-sidebar-mini .sidebar .info, .main-sidebar.main-sidebar-mini .nav.nav-pills.nav-sidebar.flex-column > li >a>.leftSideIcon{
    transform: rotateY(-90deg);
    transform-origin: 0 0;
    opacity: 0;
    transition: all 0.3s ease-in-out;
}

.main-sidebar.main-sidebar-mini:hover .nav.nav-pills.nav-sidebar.flex-column > li >a>p, .main-sidebar.main-sidebar-mini:hover .sidebar .info, .main-sidebar.main-sidebar-mini:hover .nav.nav-pills.nav-sidebar.flex-column > li >a>.leftSideIcon{
    transform: rotateY(0deg);
    opacity: 1;
}

/*.main-sidebar.main-sidebar-mini .nav.nav-pills.nav-sidebar.flex-column > li >a>.leftSideIcon{
    display: none;
}
.main-sidebar.main-sidebar-mini:hover .nav.nav-pills.nav-sidebar.flex-column > li >a>.leftSideIcon{
    display: inline-block;
}*/

.elevation-4 {
    box-shadow: 0 14px 28px rgba(0,0,0,.25),0 10px 10px rgba(0,0,0,.22)!important;
}
[class*=sidebar-dark-] {
    /*background-color: #343a40;*/
    background-color: #000;
}
.main-sidebar {
    height: 100vh;
    overflow-y: hidden;
    z-index: 1038;
}
.main-sidebar, .main-sidebar::before {
    transition: margin-left .3s ease-in-out,width .3s ease-in-out;
    width: 250px;
}
/*.main-sidebar:hover{
    transition: margin-left .3s ease-in-out,width .3s ease-in-out;
    width: 250px!important;
}*/
.subLinkTitle{
    display: flex !important;
    align-items: center;
}

.sublinkName{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 172px;
}


.sidebar {
    color: inherit;
    height: calc(100vh - 75px);
    z-index: 4;
    overflow: auto;
    position: relative;
    overflow-scrolling: touch;
    transition-duration: .2s, .2s, .35s;
    transition-property: top, bottom, width;
    transition-timing-function: linear, linear, ease;
}
.ServiceRm{
    padding: .5rem 1.6rem;
}
.ServiceRm .RmDetail{
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px solid;
    margin-top: 60px;
    padding: 8px;
}
.IssueSupport{
    border: 2px solid;
    margin-top: 60px;
    padding: 8px;
}
.IssueSupport{
    text-align: center;
}
@media(min-width:767px){
    
    .sidebar::-webkit-scrollbar, .MuiTableContainer-root::-webkit-scrollbar {
        width: 8px;
    }

    .sidebar::-webkit-scrollbar-track, .MuiTableContainer-root::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(255,255,255,0.3); 
        box-shadow: inset 0 0 6px rgba(255,255,255,0.3); 
        border-radius: 10px;
    }

    .sidebar::-webkit-scrollbar-thumb,.MuiTableContainer-root::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(255,255,255,0.5); 
        box-shadow: inset 0 0 6px rgba(255,255,255,0.5); 
    }

    .MuiTableContainer-root::-webkit-scrollbar {
        width: 8px;
    }

    .MuiTableContainer-root::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0); 
        box-shadow: inset 0 0 6px rgba(0,0,0); 
        border-radius: 10px;
    }

    .MuiTableContainer-root::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.8); 
        box-shadow: inset 0 0 6px rgba(0,0,0,0.8); 
    }

}

[class*=sidebar-dark] .brand-link {
    border-bottom: 1px solid #4b545c;
    color: rgba(255,255,255,.8);
    width: 100%;
}
.brand-link, .brand-link > a > img{
    width: 100%;
    /*padding: 0.3em 0px;*/
    object-fit: contain;
    height: 50px;
}
.user-panel {
    border-bottom: 1px solid #4f5962;
}
.user-panel, .user-panel .info {
    overflow: hidden;
    white-space: nowrap;
}
.user-panel .image {
    display: inline-block;
    padding-left: 1.2rem;
}
/*.user-panel svg {
    height: auto;
    width: 2.1rem;
    font-size: 34px;
    color:#fff;
}*/
.user-panel .userIcon {
    /*height: auto;*/
    /*width: 2.1rem;*/
    font-size: 34px;
    color:#fff;
}

.info .userName{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 145px;
}

.user-panel .info {
    /*cursor: pointer;*/
    display: inline-block;
    padding: 5px 5px 5px 10px;
}
.user-panel, .user-panel .info {
    overflow: hidden;
    white-space: nowrap;
}
.user-panel a, .userName {
	color: #c2c7d0;
}
.nav-sidebar .nav-item>.nav-link {
    position: relative;
}
@media (min-width: 992px){
	.nav-sidebar{
	    white-space: nowrap;
	    overflow: hidden;
	}
}

.nav-sidebar>.nav-item {
    margin-bottom: 0;
}
.nav-sidebar .nav-link p {
    display: inline-block;
    margin: 0;
}
.nav-link p, .user-panel .info {
    transition: margin-left .3s linear,opacity .3s ease,visibility .3s ease;
}
.nav-sidebar>.nav-item .nav-icon {
    font-size: 1.2rem;
    margin-right: .8rem;
    text-align: center;
    /*width: 1.6rem;*/
}
.nav-icon .fas {
	font-size: 1.1rem;
}
.nav-sidebar .nav-link>p>.right {
    position: absolute;
    right: 1rem;
    top: .7rem;
}

.leftSideIcon{
    display: inline-block;
    float: right;
}

.nav-sidebar .nav-link>p>.right:nth-child(2) {
    right: 2.2rem;
}
[class*=sidebar-dark-] .nav-sidebar>.nav-item>.nav-treeview {
    background: 0 0;
}
.nav-sidebar .nav-treeview {
    display: none;
    list-style: none;
    padding: 0;
}
[class*=sidebar-dark-] .nav-treeview>.nav-item>.nav-link {
    color: #c2c7d0;
}
.downAngleIcon{
	display: none;
}
.leftAngleIcon{
	display: block;
}
.nav-link > p >a {
    color:#333;
}

.nav-item .active{
    color:#000!important;
}

@media only screen and (max-width: 991px){

    .main-sidebar-open {
        margin-left: 0px !important;
    }
    
    .main-header.mobile-side-open ~ .main-sidebar{
        margin-left: 0px !important;
    }

    .main-sidebar {
        margin-left: -264px;
        position: fixed;
        height: 100vh !important;
        transition: margin-left .5s ease-in-out;
    }

    li.nav-item.has-treeview {
        width: 100%;
    }

    .close_sideHeader {
        position: absolute;
        right: 6px;
        font-size: 20px;
        top: 18px;
    }
    .sidebar {
        height: calc(100vh - 12px);
    }
}
