@import "../sass_config/theme.sass";

.ActivityBlock {
	margin-top: 8px;
	box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.05);
	background-color: #fff;

	.user {
		position: absolute;
		margin-top: -16px;
		left: 32px;

		.MuiChip-root {
			padding: 8px 32px;
			border-radius: 4px; } }

	.MuiCard-root {
		padding: 0 24px;
		box-shadow: none; }

	.MuiCardHeader-content {
		margin-top: 8px;
		display: flex;
		justify-content: space-between;
		align-items: baseline; }

	.header-w {
		.MuiCardHeader-title {
			text-transform: capitalize; } }

	@include respond-up-to('sm') {
		.MuiCard-root {
			padding: 0 0px; }

		.MuiCardHeader-content {
			margin-top: 8px;
			display: block; }

		.user {
			.MuiChip-root {
				padding: 8px 8px;
				border-radius: 4px; } } } }
