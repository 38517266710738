.deleteUser{
    top: 2.7em !important;
    background-color:#dc3545!important;
}

.rm_check {
    position: absolute !important;
    right: 1px;
    top: 2.7em;
}

.rm_check .MuiSvgIcon-root {
    font-size: 1.75rem !important;
}

@media(max-width:767px){
    .deleteUser{
        top: 1em !important;
	    background-color:#dc3545!important;
        right: 5em!important;
	}
    .rm_check {
        top: 3.7em;
    }
}    
