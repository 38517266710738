.filterRow{
	width: inherit;
}
.MuiOutlinedInput-input{
    padding: 9.5px 4px !important;
}
.MuiChip-label{
    text-transform: capitalize;
}
.MuiFormControl-root{
	width: 100%;
}
.filter_lead{
	max-height: 420px;
	/*display: block !important;*/
	flex-wrap: nowrap !important;
}
.filterMenu .filterMenuCard {
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word; 
    background-clip: border-box;
}
.filter svg{
    cursor: pointer;
    color: rgba(0,0,0,.5);
    float: right;
    color: #00182e;
    top: 0.6em;
    right: 20px;
    position: absolute;
}
.filterRow{
    margin-left: 0px!important;
}

.filter_lead {
    color: inherit;
    z-index: 4;
    overflow: auto;
    position: relative;
    overflow-scrolling: touch;
    transition-duration: .2s, .2s, .35s;
    transition-property: top, bottom, width;
    transition-timing-function: linear, linear, ease;
}



.filter svg{
    font-size: 25px;
}

button:focus{
    outline: none !important;
}

.dumpFilter{
    display: flex;
}

@media(max-width:767px){
    .filter_lead{
        max-height: 340px;
        flex-wrap: nowrap !important;
    }
    .dumpFilter{
        display: block;
    }
}

@media(min-width:767px){
    .filter_lead::-webkit-scrollbar {
    width: 8px;
    }

    .filter_lead::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
        border-radius: 10px;
    }

    .filter_lead::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
    }
}