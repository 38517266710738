@import "./../../sass_config/theme.sass";


.HomePage {

	.MuiGrid-spacing-xs-4 {
		width: 100%;
		margin: 0; }

	.Sidebox {
		background-color: #fff;
		box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.05); }

	.TextField, .Dropdown {
		margin: 0 auto; }


	.active-page-space {
		background-color: #fff;
		box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.05);

		&.active-page-Activity {
			background-color: transparent;
			box-shadow: none;
			padding: 0; } }

	.CallRecordItem {
		&:first-child {
			margin-top: 16px; } }

	blockquote.comment {
		quotes: "“" "”" "‘" "’";
		font-style: italic;
		&::before {
			content: open-quote; }

		&::after {
			content: close-quote; } } }



@include respond-down-to(md) {
	.HomePage {
		.Sidebox {
			position: sticky;
			top: 67px; } } }

@include respond-down-to(sm) {
	.social-block-group-w {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		justify-content: center !important;
		z-index: 1; } }

@include respond-up-to(sm) {
	.HomePage {
		.MuiGrid-spacing-xs-4 {
			width: 100%;
			margin: auto; }


		.ActivityPage {
			.action-buttons {
				justify-content: center; }

			.Header-title {
				text-transform: capitalize; } } } }
