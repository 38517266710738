/*terms and condition styling*/
.TCPage{
	margin:2% 15%;
	padding: 3%;
	background: #fff;
}
.Title {
    width: 100%;
    float: left;
    position: relative;
    text-transform: capitalize;
    font-weight: 600;
    font-size: 1.3rem;
    color: #00182E;
}
.text-justify{
	text-align: justify;
}
.content-terms{
	padding-left: 0.5em;
}
.site_img{
	float:right;
}
@media(max-width:767px){
	.TCPage {
	    margin: 2% 3%;
	}
	.content-terms{
		padding-left: 0.25em;
	}
} 	