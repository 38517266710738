svg.nvd3-svg {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: block;
    width: 100%;
    height: 100%;
}
.nvd3 text {
    font: normal 12px Arial,sans-serif;
}
.lead_Open{
	display: inline-block;
    position: absolute;
    top: 5%;
    right: 3em;
    font-size: 14px;
}
.dashLoader{
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10;
}