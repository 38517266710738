@media (min-width: 991px){
	body:not(.sidebar-mini-md) .unselectable .content-wrapper, body:not(.sidebar-mini-md) .main-footer, body:not(.sidebar-mini-md) .main-header {
	    transition: margin-left .1s ease-in-out;
	    margin-left: 250px;
	}
    
}

.main-sidebar.main-sidebar-mini ~ .unselectable .content-wrapper, .main-sidebar.main-sidebar-mini ~ .main-header, .main-sidebar.main-sidebar-mini ~ .unselectable .main-footer {
    margin-left: 4.6rem!important;
}

/*.content-wrapper-collapsed, .main-footer-collapsed, .main-header-collapsed {
    margin-left: 4.6rem!important;
}*/

@media (min-width: 767px){
    .search-group {
        width: 30rem;
        margin-top: .5rem;
        margin-bottom: .25rem;
    }
}
@media (max-width: 767px){
    .mobile_search {
        /*background-color: #fff;*/
        margin-bottom: 0px!important;
        margin-top: 12.5px!important;
    }

    .main-header .nav-link .search_icon {
        font-size: 37px;
        padding-left:.5rem;
        top: -7px;
        position: relative;
    }
}

.navbar-white{
	background-color:#fff;
}
.main-header {
    border-bottom: 1px solid #dee2e6;
    z-index: 1034;
}
.navbar-light .navbar-nav .nav-link {
    color: rgba(0,0,0,.5);
}

.nav-item a .site_img{
    /* height: 36px; */
    width: 70%;
    float: left;
}

.main-header .nav-link {
    height: 2.5rem;
    position: relative;
}

.dropdown-menu-lg {
    max-width: 300px;
    min-width: 280px!important;
    padding: 0!important;
}

.img-size-50 {
    width: 50px;
}
.img-circle {
    border-radius: 50%;
}
.dropdown-menu-lg .dropdown-item {
    padding: .5rem 1rem;
}
.dropdown-item-title {
    font-size: 1rem;
    margin: 0;
}
.dropdown-menu-lg p {
    margin: 0;
    white-space: normal;
}
.text-sm {
    font-size: .85rem!important;
}
.messageHeader{
    z-index: 2000;
}
.main-header.headerPadding{
    padding: 12.9px;
}
@media only screen and (max-width: 991px){
    .navbar-badge {
        position: absolute;
    }
    .mobileSearch {
        position: relative;
        top: 8px;
    }
    .mobileSearchForm{
        display: none;
    }

    .filterMenu {
        width: 98% !important;
        /*right: 8% !important;*/
        position: fixed;
    }
    
    .main-header.mobile-side-open ~ .main-sidebar{
        margin-left: 0px !important;
    }
    .headerCount{
        background-color: #fff;
    }
}

.filterMenu{
    width: 30%;
    right: 0px;
    top: 7.75em;
    margin-right: -662px;
    position: fixed;
    transition: all 0.8s linear 0s;
    background-color: #fff;
    border: 1px solid #d2d2d2;
    border-radius: .25rem;
}
.filterMenuShow{
    margin-right: 0px!important;
}

.assign_magnet svg{
    font-size: 30px !important;
    margin-right: 10px;
    cursor: pointer;
    right: 1.5em !important;
    top:0.5em !important;
}
.nav-link svg{
    font-size: 22px;
}