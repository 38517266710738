.VoiceActivityBlock {
	padding-top: 16px;
	box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.05);

	.user {
		position: absolute;
		margin-top: -16px;
		left: 32px;

		.MuiChip-root {
			padding: 8px 32px;
			border-radius: 4px;
			background-color: #000000;
			color: #fff; } }

	.MuiCard-root {
		padding: 0 24px;
		box-shadow: none; }

	.MuiCardHeader-content {
		margin-top: 8px;
		display: flex;
		justify-content: space-between; }

	.listen {
		margin-left: 32px;
		border-radius: 8px;
		padding: 4px 8px; } }
