@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600,700,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,700,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap);
/*terms and condition styling*/
.TCPage{
	margin:2% 15%;
	padding: 3%;
	background: #fff;
}
.Title {
    width: 100%;
    float: left;
    position: relative;
    text-transform: capitalize;
    font-weight: 600;
    font-size: 1.3rem;
    color: #00182E;
}
.text-justify{
	text-align: justify;
}
.content-terms{
	padding-left: 0.5em;
}
.site_img{
	float:right;
}
@media(max-width:767px){
	.TCPage {
	    margin: 2% 3%;
	}
	.content-terms{
		padding-left: 0.25em;
	}
} 	
.login-page  {
    align-items: center;
    background: #e9ecef;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
}
.login-card-body{
    background: #fff;
    border-top: 0;
    color: #666;
    padding: 20px;
}

.login-logo  {
	background-color: #fff;
    text-align: center;
}
.login-logo img {
	width: 270px;
    height: 60px;
}
.login-box-msg{
    margin: 0;
    padding: 0 20px 20px;
    text-align: center;
    font-family: Source Sans Pro;
}
.login-card-body .input-group .input-group-text .input-group .input-group-text {
    background-color: transparent;
    border-bottom-right-radius: .25rem;
    border-left: 0;
    border-top-right-radius: .25rem;
    color: #777;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

@media(max-width:767px){
    
    .login-page {
        background: #fff!important;
        position: relative;
        top: 4em;
    }
} 

body {
    background: #f4f7fa !important;
    /*font-size: 14px !important;*/
    overflow-y: scroll;
}

#root{
    position: relative;
}

.col_padding {
    padding-left: 10px !important;
    padding-right: 10px !important;
}

/*.content-wrapper-collapsed, .main-footer-collapsed, .main-header-collapsed {
    margin-left: 4.6rem!important;
}*/

.MuiPaper-elevation4{
    box-shadow: none !important;
}
.MuiAppBar-colorDefault{
    background-color: #fff !important;
}
.MuiButton-root{
    -webkit-writing-mode:'Montserrat'!important;
    -ms-writing-mode:'Montserrat'!important;
        writing-mode:'Montserrat'!important;
}

.login-box {
    width: 350px;
}

.elevation-2 {
    box-shadow: 0 3px 6px rgba(0,0,0,.16),0 3px 6px rgba(0,0,0,.23)!important;
}
.img-circle {
    border-radius: 50%;
}
.nav-link{
	cursor: pointer;
    padding: .5rem 1.6rem !important;
}
.main-footer {
    background: #fff;
    border-top: 1px solid #dee2e6;
    color: #869099;
    padding: 1rem;
}
.show{
	display:block!important;
}
.hide{
	display:none!important;
}
.inlineShow{
	display:inline-block!important;
}
.textEllipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.font-small{
    font-size: 12px;
}

.cursor-point{
    cursor: pointer!important;
}

.btn.disabled,.MuiButton-contained.Mui-disabled, .btn:disabled {
    opacity: .65;
    color: #fff!important;
    cursor: not-allowed !important;
    background-color: #6b5c5a !important;
    border-color: #6b5c5a !important;
    pointer-events: auto !important;
}
.btn.disabled,.MuiButton-contained.Mui-disabled:hover{
	background-color: #6b5c5a !important;
}

.sweet-alert{
    left : 0 !important;
    top : 0 !important;
}

.headerBar{
    padding-left: 8px!important;
}
/*
.MuiAutocomplete-popper{
    width: 250px !important;
}*/

.ReferBanner {
    background: url(https://refrance.s3.ap-south-1.amazonaws.com/mymagnet/ReferBannerMagnet.svg);
    background-size:auto;
    background-repeat:no-repeat;
    background-position:top center;
    padding:5% 0 15% 0;
}
.title{font-size:28px;}
.content{font-size:16px;}

.InputField{
    z-index: 100;
    bottom: 4.5em;
    left: 6em;
}

.quesAns{border: 2px solid #E2E2E2; border-radius: 4px; padding: 1em;}
.ques{color:#800000; font-size:18px; margin-left: 20px;}
.quesIcon{font-size:20px; color:#800000; font-weight:bolder; border:2px solid #800000; border-radius:50%;cursor: pointer;}

.quesAns p{
    text-align: justify;
}

span svg {
    overflow: hidden;
    vertical-align: text-bottom;
}
.socialCard{position:absolute!important;z-index:200;top:1em;left:6em;}

@media only screen and (max-width:768px){
    .InputField{margin: 0;}
    .ReferBanner {
        background: url(https://refrance.s3.ap-south-1.amazonaws.com/mymagnet/MobReferBannerMagnet.svg);
        background-size:cover;
        padding:5% 0 5% 0;
    }
    .ques{ font-size:16px; }
    .title{ font-size:20px; margin-top: 125px;}
    .content{ font-size:14px; }

    .InputField{
        z-index: 100;
        bottom: 0.5em;
        left: 0em;
    }
    .work h3{
        padding-top: 1em;
        padding-bottom: 1em;
    }

    .pagination li {
        padding: 4px 12px !important;
    }
    .socialCard{position:absolute!important;z-index:199;top:8.5em;left:0;}

    .MuiButton-label:focus, .MuiIconButton-label:focus, .MuiIconButton-root:focus,button:focus {
        outline: none !important;
    }
    .focusSwitch{    
        position: absolute;
        right: 40px;
        top: 3px;
    } 
    .autoAssignSwitch{    
        float: right;
        margin-bottom: 0px !important;
        display: inline;        
    }   
    .autoAssignSwitch .MuiFormControlLabel-root {
        margin-right: 10px;
    }
    .col_padding {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .errorMsg {
        font-size: 20px !important;
    }
}
@media only screen and (max-width:425px){
    /*body{
        font-size: 12px;
    }*/
    .ListContent span.valueText{
        font-size:14px!important;
    }
    
    .MuiAutocomplete-popper{
        width: 300px !important;
    }
}
.detailText{
    color: #0E3746;
}

.ListContent span.valueText{
    font-size:14px;
    /*font-weight: 500;*/
}
 .detailLead span.valueText{
    font-size:13px;
}

 .detailProject span.valueText{
    font-size:14px;
 }

.material_tab{
    max-height: 400px;
    overflow-x: hidden;
}

.MuiButton-containedPrimary, .MuiButton-containedPrimary:hover,.PrivateTabIndicator-colorPrimary-909{
    background-color: #0E3746 !important;
}
.MuiTab-textColorPrimary.Mui-selected{
    color: #0E3746 !important;
}

/* .MainList .ListContent:before {
    content: '';
    width: 5px;
    height: 100%;
    position: absolute;
    background-color: #002984;
    left: -1px;
    top: 0;
} */
a:hover{
	text-decoration: none !important;
}

.errorMsg {
    font-size: 22px;
    font-weight: 600;
    opacity: 0.6;
}

.bgCommon,.MuiFab-primary {
    background-color: #00182e !important;
}

.fs-14{
    font-size: 14px !important;
}

.fs-material{
    font-size: 0.875rem !important;
}

.fs-16{
    font-size: 16px !important;
}

.fs-12{
    font-size: 12px !important;
}

.fs-20{
    font-size: 20px !important;
}

.fs-22{
    font-size: 22px !important;
}

.fs-26{
    font-size: 26px !important;
}

.fs-40{
    font-size: 40px !important;
}
.fw-800{
    font-weight: 800;
}
/* Project Floor Plan Styling*/
.floor-title{
    font-weight: 600;
}
.floor-title{
    font-weight: 600;
}
.ButtonPart{display:inline-block;}

.SocialShare{
    position: absolute !important;
    flex-direction: row !important;
    bottom: -60px;
    z-index: 15;
    padding: 10px;
}

.SocialShare.hide{
    opacity: 0;
}

.SocialShare.show{
    display: flex !important;
    opacity: 1;
    animation: fadeIn ease .6s;
    -webkit-animation: fadeIn ease .6s;
    -moz-animation: fadeIn ease .6s;
    -o-animation: fadeIn ease .6s;
    -ms-animation: fadeIn ease .6s;
}

@keyframes fadeIn{
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-webkit-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
.ListContent .kuchhbhi{
    margin-bottom: 30px;
}

.cursor-pointer{
    cursor: pointer;
  }
.cursor-notallowed{
    cursor: not-allowed;
}

.SocialIcon{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
}
.RatingSection{
    /* width: 320px; */
    width: 285px;
    position: fixed;
    bottom: 0;
    right: 15px;
    z-index: 99;
}
.RatingSection .card{
    /* padding: 32px !important; */
    padding: 12px 32px !important;
}
.TodaysFeeling .Feeling{
    transition: 0.2s linear;
}
.TodaysFeeling .Feeling .feedback{
    /* color: #7e848489; */
    color: #f09a00;
}
.TodaysFeeling .Feeling:hover{
    -webkit-transform: scale(1.15);
            transform: scale(1.15);
}
/* HIDE RADIO */
[type=radio] { 
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }
  
.TodaysFeeling .Feeling svg.feedback{
    transition: 0.2s linear;
}
  [type=radio]:checked + svg.feedback {
    /* outline: 2px solid #f00; */
    /* color: #f09a00; */
    -webkit-transform: scale(1.35);
            transform: scale(1.35);
  }

.slide-left {
    width: 100%;
  }
  
  /***** Slide Right *****/
  .slide-right {
    -webkit-animation: 5s slide-right;
            animation: 5s slide-right;
  }
  @-webkit-keyframes slide-right {
    from {
      margin-left: -100%;
    }
    to {
      margin-left: 0%;
    }
  }
  @keyframes slide-right {
    from {
      margin-left: -100%;
    }
    to {
      margin-left: 0%;
    }
  }
  
  /***** Slide Left *****/
  .slide-left {
    -webkit-animation: 3s slide-left;
            animation: 3s slide-left;
  }
  @-webkit-keyframes slide-left {
    from {
      margin-left: 100%;
    }
    to {
      margin-left: 0%;
    }
  }
  @keyframes slide-left {
    from {
      margin-left: 100%;
    }
    to {
      margin-left: 0%;
    }
  }
@media(max-width: 480px){
    .floor-title{font-size: 14px;}
    .floor-title sub {bottom: 5px;}
    .ButtonPart {margin-top: 5px;}
    .ButtonPart:last-child {margin-right: 0;}
    .ButtonPart button { width: 96%; text-align: center;}
    .bg-red{border: 1px solid #dc3545 !important;}
    .bg-blue{border: 1px solid #007bff !important;}

    .AutoAssign .visible{display: block!important;}
    .AutoAssign .invisible{display: none!important;}
    .SocialShare{bottom: -55px; padding: 6px; left:8.5%; right: 8.5%;}
    .SocialShare .react-share__ShareButton svg, .SocialIcon{
        width: 40px;
        height: 40px;
    }
}

.pointer {
    cursor: pointer;
}
.NameCard{
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 6px;
    padding: 1em;
    text-transform: capitalize;
}

.percent_bar, .audit_count{
    position: absolute;
    right: 1.5%;
}
.audit_count{
    width: 30px;
    height: 30px;
    border: 1px solid black;
    border-radius: 50%;
    text-align: center;
    margin-right: 3px;
    padding-top: 2.5px;
}

.percent_bar.bg-50 {
    width: 48px; height: 48px;
    background: url('https://refrance.s3.ap-south-1.amazonaws.com/css_sprites.png') -10px -10px;
}

.percent_bar.bg-60 {
    width: 48px; height: 48px;
    background: url('https://refrance.s3.ap-south-1.amazonaws.com/css_sprites.png') -78px -10px;
}

.percent_bar.bg-70 {
    width: 48px; height: 48px;
    background: url('https://refrance.s3.ap-south-1.amazonaws.com/css_sprites.png') -10px -78px;
}

.percent_bar.bg-80 {
    width: 48px; height: 48px;
    background: url('https://refrance.s3.ap-south-1.amazonaws.com/css_sprites.png') -78px -78px;
}

.scrollCard{
    height: 254px;  
    overflow-y: auto;
}
.scrollCard::-webkit-scrollbar {
    width: 8px;
}
 
.scrollCard::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    border-radius: 10px;
}
 
.scrollCard::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0,0,0,0.4); 
}
@media (max-width:768px){
    .percent_bar{
        position: absolute;
        top: 35%;
    }
    .audit_count{
       top: 100px;
       width: 27px;
       height: 27px;
       padding: 1px;
    }
}

.modal-width-90 .modal-dialog {
    max-width: 90% !important;
}

.rank-position {
    border-radius: 50%!important;
    width: 35px;
    height: 35px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    float: left;
    color: #fff;
    background-color: #999;
}

svg.nvd3-svg {
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: block;
    width: 100%;
    height: 100%;
}
.nvd3 text {
    font: normal 12px Arial,sans-serif;
}
.lead_Open{
	display: inline-block;
    position: absolute;
    top: 5%;
    right: 3em;
    font-size: 14px;
}
.dashLoader{
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10;
}
.close_form {
    position: absolute;
    cursor: pointer;
    top: 12px;
    right: 20px;
}
.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 0px 65px 0px 0px !important; 
}
.MuiInputLabel-formControl {
    top: -8px !important;
}
.projectForm textarea, .project_name .MuiAutocomplete-hasPopupIcon{
	width: 100%;
}
.showFildes{
	text-decoration: underline;
	cursor: pointer;
	font-size: 12px;
}
.MuiFormControl-marginNormal{
    margin-top: 0px !important;
}
.MuiSelect-selectMenu,.MuiListItem-gutters {
    text-transform: capitalize;
}
.MuiAutocomplete-popper li {
    text-transform: capitalize;
}

.bulk_file a {
    text-decoration: underline;
    color: #6b5c5a;
}

.bulk_file svg {
    font-size: 20px;
}

.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover{
    background-color: rgba(0, 0, 0, 0.13) !important;
}
.pdfPreviewSection{
  width: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
  display: inline-block;
  font-family: "Nunito",sans-serif !important;}

.PdfPreiview {
  max-width:7in;
  display: inline-block;
  overflow: hidden;
  font-size: 62.5%;
 
}
.PdfPreiview.Max {
  max-width:100% !important;
  display: inline-block;
  overflow: hidden;
  font-size: 62.5%;
 
}

.BrandLogo {
  width: 100%;
  display: inline-block;
  margin: 10px 0;
  max-width: 200px;
}
.TextRight {
  text-align: right;
}
.FactBanner {
  margin: 30px 0;
  position: relative;
  width: 100%;
  display: inline-block;
}
.FactBanner img {
  width: 100%;
  max-width: 100%;
}
.ProjectName {
  position: absolute;
  bottom: 10%;
  right: 10%;
  color: #fff;
  text-align: right;
}

.ProjectName h1 {
  font-weight: 700;
  font-size:3em;
  text-shadow: 1px 1px 1px #000;
  color: #fff;
}
.HeadingTitle {
  font-size:2.2em;
  font-weight: 800;
  margin-bottom: 30px;
  display: inline-block;
}
.ProjectHeightImage:before {
  position: absolute;
  content: "";
  width: 150px;
  background: url("https://refrance.s3.ap-south-1.amazonaws.com/homesfy/AfterStyle.png");
  height: 150px;
  background-size: contain;
  right: -70px;
  top: 10%;
  background-repeat: no-repeat;
  -webkit-transform: rotate(-4deg);
          transform: rotate(-4deg);
}

.FactCard {
  border-color: #f59a00 !important;
  margin-bottom: 20px;
  border-radius: 10px !important;
}
.FactCard .MuiCardContent-root {
  text-align: center;
  box-sizing: border-box;
  padding: 10px !important;
}
.FactCard h4 {
  font-weight: 800;
  font-size: 1.4em;
  margin: 0 0 5px 0;
}
.FactCard h6{margin: 0;font-size: 1.2em;}
.PriceTable thead th {
  color: #f59a00;
  border-bottom-color: #f59a00 !important;
  border-right: 1px solid #f59a00;
}

.PriceTable thead th:last-child {
  border-right: 0;
}

.PriceTable {
  border: 1px solid #f59a00 !important;
  border-radius: 10px !important;
}

.PriceTable tbody th,
.PriceTable tbody td {
  border-right: 1px solid #f59a00;
  border-bottom-color: #f59a00 !important;
}
.GallerySection img {
  border: 1px solid #e2e2e2;
  width: 100%;
}
.PaddingRight0 {
  padding-right: 0 !important;
}
.GallerySection li {
  width: 32%;
  display: inline-block;
  margin-right: 2%;
  padding: 0;
  margin-bottom: 2%;
}

.GallerySection li:nth-child(3n + 3) {
  margin-right: 0;
}
.AmenitiesSection li {
  position: relative;
  width: 50%;
  display: inline-block;
  padding-left: 30px;
  box-sizing: border-box;
  font-size: 1.4em;
  line-height: 20px;
}
.AmenitiesSection li.PDFList{padding-left:20px;}
.AmenitiesSection li:before {
  content: "";
  position: absolute;
  left: 0;
  border-left: 13px solid #f59a00;
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  top: 11px;
}
.AmenitiesSection li.PDFList:before {
  content: "";
  position: absolute;
  left: 0;
  border-left: 6px solid #f59a00;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  top: 11px;
}
.LocationTitle {
  line-height: 20px;
  font-size: 16px;
  text-transform: capitalize
}
.LocationContent {
  line-height: 20px;
  width: 100%;
  display: inline-block;
  font-size:1.4em;
}
.Title {
  font-weight: 800;
  font-size: 10pt;
  text-transform: capitalize;
}

.Content {
  font-size:8pt;
}
.pdfPreviewSection .Overview{
  font-size: 14px;
}
.Disclaimer{
  font-size: 10px;
}
@media (max-width:991px) {
  .ProjectHeightImage:before{    
    width: 80px;
    right:0;
    top: auto;
    bottom: 0;
    height: 72px;
  }
  .GallerySection li{
    width: 50%;
    margin: 0;
    
  }
  .AmenitiesSection li{width:100%;}
  .LocationContent {
    margin-bottom: 20px;
}
}
.content-wrapper {
    /*background: #fff;*/
    /*padding-bottom: 60px;*/
    min-height: 750px;
}
.content-header {
    padding: 15px .5rem;
}
.breadcrumb{
	padding: 0px!important;
}

.magnetShort{
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.header_title{
	font-size: 1.4rem;
	font-family: inherit;
    font-weight: 400;
    line-height: 1.2;
}
.small-box {
    border-radius: .8rem;
    box-shadow: 0 0 1px rgba(0,0,0,.125), 0 1px 3px rgba(0,0,0,.2);
    display: block;
    margin-bottom: 20px;
    position: relative;
}
.small-box>.inner {
    padding: 10px;
}
@media (min-width: 1200px){
	.col-lg-3 .small-box h3, .col-md-3 .small-box h3, .col-xl-3 .small-box h3 {
	    font-size: 2.2rem;
	}
}
.small-box h3, .small-box p {
    z-index: 5;
}
.small-box h3 {
    font-size: 2.2rem;
    font-weight: 700;
    margin: 0 0 10px 0;
    padding: 0;
    white-space: nowrap;
}
.inner{
	color: #fff;
}
.small-box p {
    font-size: 1rem;
}
.small-box .icon {
    color: rgba(0,0,0,.15);
    z-index: 0;
}
.small-box .icon>.dash_card_icon {
    font-size: 70px;
    top: 20px;
}
.small-box .icon>.dash_card_icon {
    font-size: 90px;
    position: absolute;
    right: 15px;
    top: 15px;
    transition: all .3s linear;
}
.small-box>.small-box-footer {
    background: rgba(0,0,0,.1);
    color: rgba(255,255,255,.8);
    display: block;
    padding: 3px 0;
    position: relative;
    text-align: center;
    text-decoration: none;
    z-index: 10;
}

.desk_head{
    margin-bottom: 0.5rem;
    /*text-align: center;*/
    /*text-transform: uppercase;*/
    padding-top: .5rem;
    padding-bottom: .5rem;
    /*font-weight: 600;*/
    color: #0E3746!important;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 22px;
}
.desk_count, .dashboard_label{
    /*margin-bottom: 0.5rem;*/
    /*text-align: center;*/
    /*text-transform: uppercase;*/
    padding-top: .5rem;
    /*padding-bottom: .5rem;*/
    font-weight: 500;
    color: #41A9C1!important;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 22px;
}
.RatioCard{
    height: 135px;
}
.dashboard_label{
    font-size: 20px;
}

.customTooltip{ 
    text-transform: capitalize;
    padding: 8px 0px;
    color: lightblue;
    font-size: 16px;
}
.fontColor{ 
   color: #48a9c1;
}

/*==============================New freelancer css ===========================*/
.custom_button {
  padding: 5px 20px;
  border-radius: 0.25rem;
  font-size: 12px;
  transition: all 0.3s ease-in-out;
  color: #fff;
  background-color: #103746;
  border-color: #103746;
  width: 70%
}

.tab-content {
    padding: 35px 30px;
    background: #fff;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.05);
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    border: none;
    color: #111;
    box-shadow: 0 -5px 10px 0 rgba(0, 0, 0, 0.05);
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff !important;
    border-color: #dee2e6 #dee2e6 #fff;
}

.tab_text_head{
  color: #17839d;
  font-size: 15px;
}

.tab_text_sub_head{
  color: #0e3746;
  font-size: 16px;
}

.path-16 {
  object-fit: contain;
  border: solid 1px #e4e4e4;
  background-color: #fbfbfb;
}
.left_border {
  border-left: 1px solid #eaeaea;
}   
.table_margin{
  padding:13px 0; margin-right:40px;
}
.spends_value {
  font-size: 25px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.24;
  letter-spacing: normal;
  color: #41a9c1;
}
.spends_text {
  font-size: 23px;
  color: #000;
}

.progress-bar{
    background-color: #41A9C1 !important;
    /*box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.3);*/
}
.progressCount{
    position: absolute;
    right: 4px;
    color: #000;
}

.popup_hover {

  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}
.popup_hover:hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)
}

/*.progress-bar.progress-c-theme {
    background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
}*/
.leadsBlock{
    font-size: 14px;
    color: #888;
    font-weight: 400;
}
.dot {
  height: 10px;
  width: 10px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
  margin-left: 10px;
}

.open_leads_tags {
  font-size: 12px;
  color: #000;
}
.card_font_25 {
  font-size: 25px;
  color: #000;
}
.card_font_20 {
    font-size: 20px;
    color: #000;
  }
.tab_scroll {
    overflow-y: auto;
    max-height: 620px;
}

.progress {
    height: 12px;
    font-size: 10px;
    background-color: #f4f7fa !important;
    border-radius: 0;
    overflow: visible;
}

.openCount{
    text-align: center;
}

/*.custom_button_nav_right1 {
  padding: 5px;
  border-radius: 0.25rem;
  font-size: 12px;
  transition: all 0.3s ease-in-out;
  color: #fff;
  background-color: #103746;
  border-color: #103746;
  width: 120px;
  line-height: 1.5;
  margin-right: 10px;
}

.custom_button_nav_right2 {
  padding: 5px;
  border-radius: 0.25rem;
  font-size: 12px;
  transition: all 0.3s ease-in-out;
  color: #0e3746;
  background-color: transparent;
  border-color: #103746;
  width: 120px;
  line-height: 1.5;
  margin-right: 10px;
}   */


/*==============================New freelancer css ===========================*/

@media only screen and (max-width: 991px){

    .small-box p {
        font-size: 12px;
    }
    .small-box h3 {
        font-size: 1.8rem;
    }
    .magnetShort{
        padding-right: 15px !important;
       padding-left: 15px !important;
    }
    .m-b-3{
        margin-bottom: 1em !important;
    }
    .tab-content {
        padding: 12px 6px;
    }
    .custom_button {
        width:90%;
    }
    .openCount{
        text-align: right;
    }
    .dashboard_label, .customTooltip{
        font-size: 16px;
    }
    .customTooltip{ 
        padding: 3px 0px;
    }
}
.LeaderBoardSection{
    width: 100%;
    background-color: #fff;
    border:3px #fdfdfd solid;
    margin-bottom: 20px;
    /* box-shadow: 0 14px 28px rgb(0 0 0 / 25%), 0 10px 10px rgb(0 0 0 / 22%); */
    border-radius: 4px;
}
.LeaderBoardSection .LeaderTop{
    background-color: #0E3746;
    padding: 1.5em;
    border-radius: 4px;
}
.LeaderHead{
    margin-bottom: 20px;
}
.LeaderTitle,.AwardTitle, .Name{
    color: #fff;
}
.Name{
    position: relative; 
}
.Name:after {
    position: absolute;
    content: '';
    height: 1px;
    bottom: -4px;   
    opacity: .4; 
    margin: 0 auto;
    left: 0;
    right: 0;
    width: 50%;
    background: #fff;
}

.Name{
    font-weight: bold;
}
.LeaderTitle{
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 10px;
}
.Winner{
    text-align: center;
}
.AwardImg{
    border-radius: 50%;
    border: 3px solid #fff; 
    margin-bottom: 20px;
    width: 150px;
    height: 150px;
    margin: 0 auto 18px;
}
.AwardeeImg{
    position: relative;
}
.AwardIcon{
    position: absolute;
    bottom: -10px;
    margin: auto;
    left: 0;
    right: 0;
}
 
.LeaderBoardSection .slick-dots {
    margin-bottom: 5px;
  }
  .LeaderBoardSection .slick-dots li button:before {
    font-size: 12px !important;
    /* color: #0E3746 !important; */
    opacity: 1 !important;
  }
  .LeaderBoardSection .slick-dots li.slick-active button:before{
    font-size: 20px !important;
    color: #fff !important;
  }
.MainSection{
  font-family: 'Poppins', sans-serif;
  background: #fff;
}
.MainSection *{
  font-family: 'Poppins', sans-serif;
}
#bgImage {
    background-image: url(https://refrance.s3.ap-south-1.amazonaws.com/mymagnet/bg.svg);
    background-repeat: no-repeat;
    background-position:center;
    position: relative;
    width: 100%;
    height: 100vh;
  }
#bgBlob{
    background-image: url("https://refrance.s3.ap-south-1.amazonaws.com/mymagnet/blob.svg");
    background-repeat: no-repeat;
    position: relative;
    height: 100%;
}
.brandImg{
    height: 75px;
}
.w-90{
    width: 90% !important;
    margin: 0 auto !important;
}
.RegisterMsg{
    font-size: 1.35em;
}
 
.mgSlider .slick-dots {
  width: 5% !important;
  top: 20% !important;
  right: 0 !important;
  display: flex !important;
  flex-direction: column !important;
}
.mgSlider .slick-dots li button:before {
  font-size: 12px !important;
  color: #0E3746 !important;
  opacity: 1 !important;
}
.mgSlider .slick-dots li.slick-active button:before{
  font-size: 20px !important;
  color: #AF4063 !important;
}
.mgSlider {
  padding: 10px 30px;
}

.text-mg{
  color: #1A3460 !important;
}
.bg-mg{
  background: #1A3460 !important;
  color: #fff !important;
}
.header-mg{
  background: #E5E5E5 !important;
}

.headerLink{
  font-weight: 600 !important;
  font-size: 18px !important;
  text-transform: capitalize !important;
  padding: 0 5px;
  margin: 0;
  color: #1A3460 !important;
}
.slideImg{
  padding: 12% 10%;
  box-sizing: border-box;
  display: inline-block;
  width: 350px;
  height: 350px;
  overflow: hidden !important;
}

.menuIcon{
  position: absolute !important;
  right: 0 !important;
  color: #000 !important;
}
.whatsapp{height: 23px !important}
@media(max-width: 480px){
  .brandImg{height: 55px;}  
}
.deleteUser{
    top: 2.7em !important;
    background-color:#dc3545!important;
}

.rm_check {
    position: absolute !important;
    right: 1px;
    top: 2.7em;
}

.rm_check .MuiSvgIcon-root {
    font-size: 1.75rem !important;
}

@media(max-width:767px){
    .deleteUser{
        top: 1em !important;
	    background-color:#dc3545!important;
        right: 5em!important;
	}
    .rm_check {
        top: 3.7em;
    }
}    

.filterRow{
	width: inherit;
}
.MuiOutlinedInput-input{
    padding: 9.5px 4px !important;
}
.MuiChip-label{
    text-transform: capitalize;
}
.MuiFormControl-root{
	width: 100%;
}
.filter_lead{
	max-height: 420px;
	/*display: block !important;*/
	flex-wrap: nowrap !important;
}
.filterMenu .filterMenuCard {
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word; 
    background-clip: border-box;
}
.filter svg{
    cursor: pointer;
    color: rgba(0,0,0,.5);
    float: right;
    color: #00182e;
    top: 0.6em;
    right: 20px;
    position: absolute;
}
.filterRow{
    margin-left: 0px!important;
}

.filter_lead {
    color: inherit;
    z-index: 4;
    overflow: auto;
    position: relative;
    overflow-scrolling: touch;
    transition-duration: .2s, .2s, .35s;
    transition-property: top, bottom, width;
    transition-timing-function: linear, linear, ease;
}



.filter svg{
    font-size: 25px;
}

button:focus{
    outline: none !important;
}

.dumpFilter{
    display: flex;
}

@media(max-width:767px){
    .filter_lead{
        max-height: 340px;
        flex-wrap: nowrap !important;
    }
    .dumpFilter{
        display: block;
    }
}

@media(min-width:767px){
    .filter_lead::-webkit-scrollbar {
    width: 8px;
    }

    .filter_lead::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
        border-radius: 10px;
    }

    .filter_lead::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
    }
}
@media (min-width: 768px){
	.listing.col-md-3 {
	    flex: 0 0 20% !important;
	    max-width: 25%;
	}
}
.login-page  {
    align-items: center;
    background: #e9ecef;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
}

.btn-submit {
    background: #7e153c !important; 
    color: white !important;
}


/*Listing css start below*/
.listContainer{
    display: flex;
    flex-wrap: wrap;
}

.MainList {
    width: 100%;
    float: left;
    background: #f1f3f6;
}

.MainList .ListContent {
    width: 100%;
    float: left;
    padding: 20px;
    position:relative;
    min-height: 90px;
}
.MainList .ListContent:before {
    content: '';
    width: 4px;
    height: 100%;
    position: absolute;
    /* background-color: #f00; */
    left: -1px;
    top: 0;
}

.today-strip:before {
    background-color: #840072 !important;
}
.today-past-strip:before {
    background-color: #ffc107 !important;
}
.today-future-strip:before {
    background-color: #28a745 !important;
}
.past-strip:before {
    background-color: #dc3545 !important;
}
.future-strip:before {
    background-color: #343a40 !important;
}
.default-strip:before {
    background-color: #002984 !important;
}

.MainList .ListContent h6 {
    font-size: 12px;
}

.MainList .ListContent h3 {
    font-size: 12px;
}

.MainList .ListContent p {
    font-size: 12px;
}
.MainList i {
    float: left;
    margin-right: 5px;
    margin-top: 1px;
    color:#5296c1;
}
.MainList .ShortContent {
    width: 100%;
    /*padding: 20px 0px 0px;*/
    float: left;
    
}
.FollowupDate {
    float: right;

}
.Hide {
    display: none;
}
.MainList a {
    color: #5296c1;
}
.MainList .ShortContent i {
    margin-top: 6px;
}
/*Listing css start above*/

/*relevent css start below*/
.Action {
    padding: 2px 2px;
    background-color: #245983;
    border-radius: 50%;
    color: #fff;
    object-fit: contain;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
    width: 26px;
    /* position: absolute; */
    /* right: 1em; */
    /* top: 8px; */
    text-align: center;
    cursor: pointer;
}
.Action > svg {
    margin: 0;
    float: none;
    color: #fff;
}
.ActionSection {
    padding: 0px;
    width:100%;
    float:left;
    display:none;
}

.ActionSection a {
    display: table;
    margin: 0 auto;
    text-align: center;
    padding: 10px 40px;
    background-color: #5ca847;
    border-radius: 25px;
    
}

.ActionSection a svg {
    color: #fff;
    font-size: 20px;
    text-align: center;
    display: table;
    margin: 0;
}

a.fullDetail {
    position: absolute;
    right: 20%;
}

.fold-lead{
    text-align:left;
}

.pagination li{
    padding: 4px 16px;
    background-color: #fff;
    border: 1px solid #e8e3e3;
    border-radius: 2px; 
    cursor: pointer;
}
.pagination a{
    color:#333;
}
.paginationBlock{
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: 1em;
    margin-bottom: 1em;
}
.active{
    background-color: #f3f5f7!important;
}

/*.WhatsAppBG {
    top: 5em;
    background-color:#2FD356;
}*/
.bg-success{
   background-color:#2FD356!important; 
}
 button.bg-success:hover {
    background-color: #2FD356!important;
}
.MuiButton-containedSecondary {
    color: #fff;
    background-color: #2FD356;
}
.MuiButton-containedSecondary:hover {
    background-color: #2FD356;
}

.assign_rm svg {
    font-size: 30px !important;
    /*margin-right: 10px;*/
    cursor: pointer;
    right: 100px;
    top:0.5em;
}

.WhatsAppBG {
    /*top: 1.3em;*/
    /*width: 34px;*/
    /*height: 34px;*/
    /*margin: 0 auto;*/
    text-align: center;
    /*border: 1px solid #1e7e34;*/
    background-color: #1e7e34!important;
    /*border-radius: 50%;*/
}
.callBG{
    /*width: 34px;*/
    /*height: 34px;*/
    /*margin: 0 auto;*/
    text-align: center;
    top: 2.7em;
    /*border: 3px solid #3F51B5;*/
    background-color:#3F51B5!important;
    /*border-radius: 50%;*/
}
.deleteLead{
    top: 5em;
    background-color:#dc3545!important;
}

.revertLead{
    top: 5em;
    background-color:#0E3746!important;
}

.lead_check{
    /* position: absolute !important; */
    /* right: 8px; */
    /* top: 9em; */
    border: 3px solid #3F51B5;
    font-size: 20px;
}

  /*transition-duration: 0.5s*/
.WhatsAppBG .fa-whatsapp{
    padding-top: 2px;
    font-size: 20px;
    text-decoration: none;
    color: white;
}
.callBG .fa-phone-alt{
    /*padding-top: 3px;*/
    text-decoration: none;
    /*font-size: 22px!important;*/
    color: white;
}


.leadDetail{
    top: 9.4em;
}

.leadDetailBg{
    background-color:#0E3746!important;
} 
.leadInternalStatus{
    top: 11.4em;
}
.leadInternalStatusBg{
    background-color:#0E3746!important;
}
.CrossSale{
    background-color:#0E3746!important;
    top: 13.4em;
}
.ActionBg {
    background: linear-gradient(40deg, #45cafc, #303f9f);
}

.ShortContent span{
    font-size: 12px;
}

.sortComment{
    max-height: 210px;
    width: 65%;
}
.commentAdd{
    width: 34%;
    float: right;
}
.inputComment{
    width: 90%;
}

.commentBtn{
    margin: 10px 0px !important;
}

.noComment{
    height: 100%;
}

.noComment span{
    top: 45%;
}

.leadMainHeader{
    position: -webkit-sticky;
    position: sticky;
    top: 3.57em;
    z-index: 100;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
}
.overDue{
    padding: 5px 10px;
}
.shortLeadComment{
    position: relative;
    justify-content: space-between;
}

.MuiFab-root{
    width : 35px!important ;
    height: 35px!important ;
}

.caller_lead {
    /* padding-left: 2.2em!important; */
    /* padding-right: 2.5em!important; */
    width: 120.21px;
}
svg.MuiSvgIcon-root.caller_icon {
    font-size: 2em;
}
.MuiDrawer-paper{
    display: unset !important;
}
@media(max-width:767px){
    .MuiDrawer-paper{
        display: unset !important;
    }
    .ActionSection{display:block;}
    .Action{margin-bottom: 12px;}
    .revertLead{right: 0.2em;}
        .MainList .ListContent{padding: 10px 20px;}
    .textRight{text-align:right;}
    .col-xs-6 {
        width: 50%;
    }

    .MainList .ShortContent{
        padding: 0px;
    }

    a.fullDetail {
        position: absolute;
        right: 10%;
    }

    .mobile_whatappLead{
        font-size: 18px;
    }
    .deleteLead{
        right: 2px;
    }
    .lead_check{
        /* top: 3.8em; */
        /* right: 0px; */
        padding-left: 4px!important;  
        padding-right: 4px!important;  
    }
    .leadDetail{
        right: 2px;
        top: 5.4em;
    }
    .leadInternalStatus{
        top: 7.8em;
        right: 2px;
    }
    .CrossSale{
        top: 10em;
        right: 2px;
    }
    .inputComment{
        width: 70%;
    }
    .commentBtn{
        top:10px;
    }

    .sortComment {
        width: 100%;
    }
}
.addLeadIcon{
    position: absolute;
    right: 3.5rem;
    font-size: 35px !important;
    top: 0.75rem;
    cursor: pointer;
}

.action_box{
    position: absolute;
    right: 10px;
    bottom: 10px;
    display: flex;
    width: 250px;
    justify-content: space-between;
    align-items: center;
}
.action_icons{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.action_icons > *{
    margin-left: 1em;
 }
 .action_icons > .WhatsAppBG{
    margin-right: 0!important;
 }
.HeaderDropdown{
    position: absolute;
    right: 12%;
    top: 25%;
    width: 10.25%;
}
@media(max-width:767px){
    .fold-lead{
        text-align:left;
    }

    .action_box{
        right: 8px;
        bottom: unset;
        top: 16px;
        flex-direction: column;
        width: auto;
    }

    .action_icons{
        display: none;
    }
    .viewMore{
        justify-content: space-between !important;
    }
    .HeaderDropdown{
        right: 40%;
        top: 17%;
        width: 24.25%;
    }
} 

.plan-selected {
    border: 3px solid #38bd42 !important;
}

.cursor-pointer {
    cursor: pointer;
}

.plan-pointers {
    border: none !important;
    padding-left: 0 !important;
    padding-bottom: 0 !important;
    background: none !important;
}

@media (max-width: 990px) {
    .arrow-image {
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAApgAAAKYB3X3/OAAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAF9SURBVEiJpdbBSlVBHMfxzyRc2oQQLlq6E5eSPkELaeGmB3AjtGlhy67korgQIoiVPUAPEEHguq1mm97CNi0KNDUYF3rgON05Z+Y68OOcxW9+3+E/f2YmxBjlRghhBkt4eK1FDPC9paMY43E2JMb4nzCFIc4Qe3SBEQZjs8aEz+OwIDjVDyx0ArCK0wnCG53j2VgA5nByi/A2ZOEG4LrmB5kJa7iLncpyDdqAjYzxX1LC7QrIqOnQ+z3dMkwgW4WACzyA5QLzZgJ5UwhZgc1C86sEMiqZA19q69qCvO7x78NxBSDiaQL52OH9ecdV39aMP81PCCHgXof3HD5VrP55svoPPf7P8GLC8L2COS/h0QTh7wsX9RimdZ9Bafi7wvAzzDST1jOmv0n424r9GrbPooCvGWNz2O1WhB9iKj2uZ/G7IiSnU8znLpwnt4ScYLXvypztKFeXDjDXeye39mS9p7va3bLR1DxV6Hm2TLt6qiy1NMA3HDXfGOOvXMYlzvShNTOJWZgAAAAASUVORK5CYII=);
        background-repeat: no-repeat;
        background-position: 95% 50%;
    }
}    

.modal-content{
  width: 1200px;
}
.commnets{
	display: flex;
	justify-content: flex-end;
}
.commnets span{
	color:#245983;
}
.commnets span:hover{
	text-decoration: underline;
	cursor: pointer;
}

.commentBox{
	max-height: 240px;
}

.commentText::first-letter {
    text-transform: uppercase;
}

.personal_image img{
	width: 100%;
  cursor: pointer;
}

.comment_loader{
    z-index: 100;
    position: absolute;
    left: 50%;
    top: 30%;
}

.ReactModal__Overlay{
  z-index: 10000!important;
}

@media(max-width:767px){
    .detailLead{
       max-height: none;
    }
}    

.addProject svg {
	font-size: 22px;
	cursor: pointer;
}

.ProjectUpdate {
    top: 42px;
}

.ListContent span, .ListContent b{
	font-size: 12px;
}
/* .MainList .ListContent:before {
    content: '';
    width: 5px;
    height: 100%;
    position: absolute;
    background-color: #002984;
    left: -1px;
    top: 0;
} */

.action_box .statusSwitch {
    position: absolute;
    right: 25px;
    top: 3px;
    width: 110px;
}

.action_box .focusSwitch{
    position: absolute;
    right: 0;
    top: 45px;
    width: 133px;
}
.UpdateCp{
	 top: 5em !important;
}

@media(max-width:767px){
    .UpdateCp {
	    top: 1em !important;
	    right: 2.8em!important;
	}
	.cpDelete{
	    top: 1em !important;
	    right: 5em!important;
	}
}    

/*.W-100{width: 100%; float: left;}
.MuiFormControl-root.MuiTextField-root {float: left;width: 100%;}
.BorderBottom{border-bottom:1.5px solid rgba(118, 118, 118,0.6);}
.PB-2{    padding-bottom: 0.7rem !important;}
.Astrik{color: #ff0000;}
.AddLead .MuiCard-root{padding:5%; margin-top: 20px;}*/

.registerPage{
	width: 70%;
}

.registerLogo img{
	width:70%;
}

.MuiFormControlLabel-root {
    float: left;
}
.MuiFormLabel-root {
    text-align: left;
}
.register-page {
	display: flex;
    /*justify-content: center;*/
    background-color: #fff;
}

@media(max-width:767px){
    .registerPage{
		width: 100%;
	}
	.login-page{
		height: auto !important;
	}
	.login-page .registerPage{
		padding: 0px !important;
	}
	.MuiStepper-root{
		padding-right: 0px !important;
		padding-left: 0px !important;
	}
} 

.node circle {
  fill: rgb(247, 106, 214);
  stroke: rgb(184, 37, 81);
  stroke-width: 1.5px;
}

.node text {
  font-size: 14px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  background-color: black;
  fill: black;
}

.node {
  cursor: pointer;
}

path.link {
  fill: none;
  stroke: #2593B8;
  stroke-width: 1.5px;
}



.red {
  fill: none;
  stroke: red;
  stroke-width: 1.5px;
  stroke-dasharray: 5;
}

.yellow{
  fill: none;
  stroke: yellow;
  stroke-width: 1.5px;
}

.grey{
  fill: none;
  stroke: grey;
  stroke-width: 1.5px;
}

.black{
  fill: none;
  stroke: black;
  stroke-width: 1.5px;
}

.blue{
  fill: none;
  stroke: blue;
  stroke-width: 1.5px;
}

.green{
  fill: none;
  stroke: green;
  stroke-dasharray: 5;
  stroke-width: 1.5px;
}

.roleTree svg{
  width: 100%;
  height: 100%;
}
.magnet-budget {
  padding: 1rem;
}

.bookings-container{padding:1rem}.bookings-container .legend-text{font-size:0.85rem}.bookings-container .booking-list .booking-item.pending-documents{border-left:5px solid #000}.bookings-container .booking-list .booking-item.pending-confirmation{border-left:5px solid #d3ff5b}.bookings-container .booking-list .booking-item.approved{border-left:5px solid #25e004}.bookings-container .booking-list .booking-item.rejected{border-left:5px solid #fb0c0c}.bookings-container .booking-list .booking-item .text-muted{font-size:12px}.bookings-container .booking-list .booking-item .valueText{font-size:14px}.bookings-container .booking-list .booking-item .booking-action{cursor:pointer}.bookings-container .row-class td{width:160px !important}.fa-link{cursor:pointer}.pending-documents{border-left:5px solid #000 !important}.pending-confirmation{border-left:5px solid #d3ff5b !important}.approved{border-left:5px solid #25e004 !important}.rejected{border-left:5px solid #fb0c0c !important}.lead-cancelled{color:red !important}.blue-pill{padding:0.2rem 1rem;background-color:#0704ff;color:#fff;border-radius:20px;min-width:70px}.exeption-lable{margin-left:7px;margin-top:2px}.bg-lead-cancelled{background-color:#ffbcad}

.hrHeader{
	font-size: 1.65rem;
	line-height: 22px;
}
.MainContainer{
	width: 100%;
	float: left;
	background-color: #fff;
}
.holderContainer{
	padding-top: 58px;
	padding-bottom: 58px;
}
.uploadHeader {
    position: absolute;
    top: 156px;
    padding: 6px;
    left: 300px;
    border: 1px solid #000;
    border-radius: 10px;
    z-index: 1;
    font-size: 14px;
    line-height: 16px;
    background-color: #fff;
}
.tableHead{
    border: 1px solid #d3d3d3;
    box-shadow: 5px 5px 5px rgb(68 68 68 / 30%);
}
.docsRow{
    border-bottom: 1px solid #d2d2d2;
}
.formContainer{
	border: 1px solid #000;
    padding: 57px 26px 20px;
}
.input-container,.doc-name{
	display: flex;
}
.fileNameInput{
	width: calc(65% - 5px);
} 
.fileName{
	width: calc(35% - 5px);
	font-size: 14px;
	line-height: 46px;
}
.serial-num{
	margin-right: 1rem;
}
.divider {
    border: 1px solid #000;
    margin-bottom: 2rem;
}

.showDocIcon{
	font-size: 20px;
    color: #800000;
    font-weight: bolder;
    border: 2px solid #800000;
    border-radius: 50%;
    cursor: pointer;
    padding: 0px 3px;
    float: right;
}
.noDoc{
    font-style: italic;
}
.HandBook{
    width: 100%;
	height: 70vh;
	border: 1px solid lightgray
}
@media (max-width: 991px){
    .uploadHeader{
        left: 34px;
    }
    .hrDocSubmit{
        margin-top: 1.5rem;
    }
    .docsRow{
        position: relative;
    }
    .docsRow .listOptionBtn{
        position: absolute;
        right: 0px;
    }
    #simple-menu .singleMenu{
        min-height: 34px;
        padding-left: 10px;
        padding-right: 10px;
        font-size: 12px;
    }
    .singleMenu svg{
        font-size: 18px;
        margin-right: 4px;
    }
}
@media (max-width: 450px){
    .HandBook {
        height: 250px;
    }
}

/*Listing css start below*/
.listContainer{
    display: flex;
    flex-wrap: wrap;
}

.MainList {
    width: 100%;
    float: left;
    background: #f1f3f6;
}

.MainList .ListContent {
    width: 100%;
    float: left;
    padding: 20px;
    position:relative;
    min-height: 90px;
}
.MainList .ListContent:before {
    content: '';
    width: 4px;
    height: 100%;
    position: absolute;
    /* background-color: #f00; */
    left: -1px;
    top: 0;
}

.today-strip:before {
    background-color: #840072 !important;
}
.today-past-strip:before {
    background-color: #ffc107 !important;
}
.today-future-strip:before {
    background-color: #28a745 !important;
}
.past-strip:before {
    background-color: #dc3545 !important;
}
.future-strip:before {
    background-color: #343a40 !important;
}
.default-strip:before {
    background-color: #002984 !important;
}

.MainList .ListContent h6 {
    font-size: 12px;
}

.MainList .ListContent h3 {
    font-size: 12px;
}

.MainList .ListContent p {
    font-size: 12px;
}
.MainList i {
    float: left;
    margin-right: 5px;
    margin-top: 1px;
    color:#5296c1;
}
.MainList .ShortContent {
    width: 100%;
    /*padding: 20px 0px 0px;*/
    float: left;
    
}
.FollowupDate {
    float: right;

}
.Hide {
    display: none;
}
.MainList a {
    color: #5296c1;
}
.MainList .ShortContent i {
    margin-top: 6px;
}
/*Listing css start above*/

/*relevent css start below*/
.Action {
    padding: 2px 2px;
    background-color: #245983;
    border-radius: 50%;
    color: #fff;
    object-fit: contain;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
    width: 26px;
    /* position: absolute; */
    /* right: 1em; */
    /* top: 8px; */
    text-align: center;
    cursor: pointer;
}
.Action > svg {
    margin: 0;
    float: none;
    color: #fff;
}
.ActionSection {
    padding: 0px;
    width:100%;
    float:left;
    display:none;
}

.ActionSection a {
    display: table;
    margin: 0 auto;
    text-align: center;
    padding: 10px 40px;
    background-color: #5ca847;
    border-radius: 25px;
    
}

.ActionSection a svg {
    color: #fff;
    font-size: 20px;
    text-align: center;
    display: table;
    margin: 0;
}

a.fullDetail {
    position: absolute;
    right: 20%;
}

.fold-lead{
    text-align:left;
}

.pagination li{
    padding: 4px 16px;
    background-color: #fff;
    border: 1px solid #e8e3e3;
    border-radius: 2px; 
    cursor: pointer;
}
.pagination a{
    color:#333;
}
.paginationBlock{
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: 1em;
    margin-bottom: 1em;
}
.active{
    background-color: #f3f5f7!important;
}

/*.WhatsAppBG {
    top: 5em;
    background-color:#2FD356;
}*/
.bg-success{
   background-color:#2FD356!important; 
}
 button.bg-success:hover {
    background-color: #2FD356!important;
}
.MuiButton-containedSecondary {
    color: #fff;
    background-color: #2FD356;
}
.MuiButton-containedSecondary:hover {
    background-color: #2FD356;
}

.assign_rm svg {
    font-size: 30px !important;
    /*margin-right: 10px;*/
    cursor: pointer;
    right: 100px;
    top:0.5em;
}

.WhatsAppBG {
    /*top: 1.3em;*/
    /*width: 34px;*/
    /*height: 34px;*/
    /*margin: 0 auto;*/
    text-align: center;
    /*border: 1px solid #1e7e34;*/
    background-color: #1e7e34!important;
    /*border-radius: 50%;*/
}
.callBG{
    /*width: 34px;*/
    /*height: 34px;*/
    /*margin: 0 auto;*/
    text-align: center;
    top: 2.7em;
    /*border: 3px solid #3F51B5;*/
    background-color:#3F51B5!important;
    /*border-radius: 50%;*/
}
.deleteLead{
    top: 5em;
    background-color:#dc3545!important;
}

.revertLead{
    top: 5em;
    background-color:#0E3746!important;
}

.lead_check{
    /* position: absolute !important; */
    /* right: 8px; */
    /* top: 9em; */
    border: 3px solid #3F51B5;
    font-size: 20px;
}

  /*transition-duration: 0.5s*/
.WhatsAppBG .fa-whatsapp{
    padding-top: 2px;
    font-size: 20px;
    text-decoration: none;
    color: white;
}
.callBG .fa-phone-alt{
    /*padding-top: 3px;*/
    text-decoration: none;
    /*font-size: 22px!important;*/
    color: white;
}


.leadDetail{
    top: 9.4em;
}

.leadDetailBg{
    background-color:#0E3746!important;
} 
.leadInternalStatus{
    top: 11.4em;
}
.leadInternalStatusBg{
    background-color:#0E3746!important;
}
.CrossSale{
    background-color:#0E3746!important;
    top: 13.4em;
}
.ActionBg {
    background: linear-gradient(40deg, #45cafc, #303f9f);
}

.ShortContent span{
    font-size: 12px;
}

.sortComment{
    max-height: 210px;
    width: 65%;
}
.commentAdd{
    width: 34%;
    float: right;
}
.inputComment{
    width: 90%;
}

.commentBtn{
    margin: 10px 0px !important;
}

.noComment{
    height: 100%;
}

.noComment span{
    top: 45%;
}

.leadMainHeader{
    position: -webkit-sticky;
    position: sticky;
    top: 4.2em;
    z-index: 100;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
}
.overDue{
    padding: 5px 10px;
}
.shortLeadComment{
    position: relative;
    justify-content: space-between;
}

.MuiFab-root{
    width : 35px!important ;
    height: 35px!important ;
}

.caller_lead {
    /* padding-left: 2.2em!important; */
    /* padding-right: 2.5em!important; */
    width: 120.21px;
}
svg.MuiSvgIcon-root.caller_icon {
    font-size: 2em;
}

@media(max-width:767px){
    .leadMainHeader{top: 3.55em;}
    .ActionSection{display:block;}
    .Action{margin-bottom: 12px;}
    .revertLead{right: 0.2em;}
        .MainList .ListContent{padding: 10px 10px;}
    .textRight{text-align:right;}
    .col-xs-6 {
        width: 50%;
    }

    .MainList .ShortContent{
        padding: 0px;
    }

    a.fullDetail {
        position: absolute;
        right: 10%;
    }

    .mobile_whatappLead{
        font-size: 18px;
    }
    .deleteLead{
        right: 2px;
    }
    .lead_check{
        /* top: 3.8em; */
        /* right: 0px; */
        padding-left: 4px!important;  
        padding-right: 4px!important;  
    }
    .leadDetail{
        right: 2px;
        top: 5.4em;
    }
    .leadInternalStatus{
        top: 7.8em;
        right: 2px;
    }
    .CrossSale{
        top: 10em;
        right: 2px;
    }
    .inputComment{
        width: 70%;
    }
    .commentBtn{
        top:10px;
    }

    .sortComment {
        width: 100%;
    }
}
.addLeadIcon{
    position: absolute;
    right: 3.5rem;
    font-size: 35px !important;
    top: 0.75rem;
    cursor: pointer;
}

.action_box{
    position: absolute;
    right: 10px;
    bottom: 10px;
    display: flex;
    width: 250px;
    justify-content: space-between;
    align-items: center;
}

.srch_action_box{
    position: absolute;
    right: 10px;
    bottom: 10px;
    display: flex;
    width: 80px;
    justify-content: space-between;
    align-items: center;
}

@media(max-width:767px){
    .fold-lead{
        text-align:left;
    }

    .action_box{
        right: 8px;
        bottom: unset;
        top: 12px;
        flex-direction: column;
        width: auto;
    }

    .viewMore{
        justify-content: space-between !important;
    }
} 


.ProfileBox{position:relative}.ProfileBox .project-name{display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical;overflow:hidden}.ProfileBox .MuiCard-root{box-shadow:none}.ProfileBox .avatar{width:72px;height:72px;margin:0 auto;left:0;right:0}






.NavigationButtonSidebarGroup .drop-down{width:100%}.NavigationButtonSidebarGroup .button-caption{text-transform:uppercase;font-size:16px}.NavigationButtonSidebarGroup .card-button{cursor:pointer;box-shadow:none;border:1px solid #000;border-radius:5px}.NavigationButtonSidebarGroup .card-button.active{background-color:#049B5A !important;color:#fff;border:1px solid transparent}.NavigationButtonSidebarGroup .card-button .MuiCardContent-root{padding-bottom:16px}.NavigationButtonSidebarGroup .stick-me{height:56px}.NavigationButtonSidebarGroup .stick-me .dd-w{background-color:#fff;position:fixed;top:0px;left:32px;right:32px}

.SocialBlockGroup{background-color:#fff;box-shadow:5px 5px 15px rgba(0,0,0,0.05)}.SocialBlockGroup .icon-ph.active,.SocialBlockGroup-Menu .icon-ph.active{color:#fff;background-color:#455a64 !important}.SocialBlockGroup .icon-ph.whatsapp-icon,.SocialBlockGroup-Menu .icon-ph.whatsapp-icon{color:#fff;background-color:#1E7E34}.SocialBlockGroup .icon-ph.whatsapp-icon.active,.SocialBlockGroup-Menu .icon-ph.whatsapp-icon.active{background-color:#1E7E34 !important;color:#fff}.SocialBlockGroup .icon-ph.call,.SocialBlockGroup-Menu .icon-ph.call{color:#fff;background-color:#3F51B5}.SocialBlockGroup .icon-ph.call.active,.SocialBlockGroup-Menu .icon-ph.call.active{background-color:#3F51B5 !important;color:#fff}.SocialBlockGroup .icon-ph.mail,.SocialBlockGroup-Menu .icon-ph.mail{color:#fff;background-color:#17A2B8}.SocialBlockGroup .icon-ph.mail.active,.SocialBlockGroup-Menu .icon-ph.mail.active{background-color:#3F51B5 !important;color:#fff}.SocialBlockGroup .icon-ph.assign,.SocialBlockGroup-Menu .icon-ph.assign{color:#fff;background-color:#CF7500}.SocialBlockGroup .icon-ph.assign.active,.SocialBlockGroup-Menu .icon-ph.assign.active{background-color:#CF7500 !important;color:#fff}.SocialBlockGroup .icon-ph.m-call,.SocialBlockGroup-Menu .icon-ph.m-call{color:#fff;background-color:#212646}.SocialBlockGroup .icon-ph.m-call.active,.SocialBlockGroup-Menu .icon-ph.m-call.active{background-color:#212646 !important;color:#fff}@media (max-width: 600px){.SocialBlockGroup{background-color:transparent;box-shadow:none}.SocialBlockGroup .corner-button{background-color:#049B5A !important;color:#fff;box-shadow:5px 5px 15px rgba(0,0,0,0.05)}}



.ActivityBlock{margin-top:8px;box-shadow:5px 5px 15px rgba(0,0,0,0.05);background-color:#fff}.ActivityBlock .user{position:absolute;margin-top:-16px;left:32px}.ActivityBlock .user .MuiChip-root{padding:8px 32px;border-radius:4px}.ActivityBlock .MuiCard-root{padding:0 24px;box-shadow:none}.ActivityBlock .MuiCardHeader-content{margin-top:8px;display:flex;justify-content:space-between;align-items:baseline}.ActivityBlock .header-w .MuiCardHeader-title{text-transform:capitalize}@media (max-width: 600px){.ActivityBlock .MuiCard-root{padding:0 0px}.ActivityBlock .MuiCardHeader-content{margin-top:8px;display:block}.ActivityBlock .user .MuiChip-root{padding:8px 8px;border-radius:4px}}

.TextField{width:100%;display:inline-flex}.TextField.no-edit{pointer-events:none}.Dropdown{width:100%}.Dropdown.no-edit{pointer-events:none}

.CallRecordBlock{padding-top:16px;background-color:#fff;box-shadow:5px 5px 15px rgba(0,0,0,0.05)}.CallRecordBlock .date{position:absolute;margin-top:-16px;left:0px;width:100%;display:flex;justify-content:center}.CallRecordBlock .date .MuiChip-root{padding:8px 16px;border-radius:4px}.CallRecordBlock .date .MuiChip-root .MuiChip-label{white-space:pre-wrap}.CallRecordBlock .no-edit{pointer-events:none}.CallRecordBlock .MuiCard-root{box-shadow:none}.CallRecordBlock .MuiCard-root .MuiChip-root{margin-top:-16px}.CallRecordBlock .MuiCardHeader-content{margin-top:8px;display:flex;justify-content:space-between;color:#fff}.CallRecordBlock .listen{border-radius:4px;padding:4px 8px;cursor:pointer;background-color:#0E3746;color:#fff}.CallRecordBlock audio{width:100%}

.NavigationButtonHeaderGroup .button{text-transform:uppercase;width:120px;margin-top:8px;color:#fff}.MuiMenu-list{background-color:#fff}

.EmailActivityBlock{margin-top:8px;box-shadow:5px 5px 15px rgba(0,0,0,0.05);background-color:#fff}.EmailActivityBlock .user{position:absolute;margin-top:-16px;left:32px}.EmailActivityBlock .user .MuiChip-root{padding:8px 32px;border-radius:4px;background-color:#000000;color:#fff}.EmailActivityBlock .MuiCard-root{padding:0 24px;box-shadow:none}.EmailActivityBlock .MuiCardHeader-content{margin-top:8px;display:flex;justify-content:space-between;align-items:baseline}@media (max-width: 600px){.EmailActivityBlock .MuiCard-root{padding:0 0px}.EmailActivityBlock .MuiCardHeader-content{margin-top:8px;display:block}.EmailActivityBlock .user .MuiChip-root{padding:8px 8px;border-radius:4px}}

.SmsActivityBlock{margin-top:8px;box-shadow:5px 5px 15px rgba(0,0,0,0.05);background-color:#fff}.SmsActivityBlock .user{position:absolute;margin-top:-16px;left:32px}.SmsActivityBlock .user .MuiChip-root{padding:8px 32px;border-radius:4px;background-color:#000000;color:#fff}.SmsActivityBlock .MuiCard-root{padding:0 24px;box-shadow:none}.SmsActivityBlock .MuiCardHeader-content{margin-top:8px;display:flex;justify-content:space-between;align-items:baseline}@media (max-width: 600px){.SmsActivityBlock .MuiCard-root{padding:0 0px}.SmsActivityBlock .MuiCardHeader-content{margin-top:8px;display:block}.SmsActivityBlock .user .MuiChip-root{padding:8px 8px;border-radius:4px}}

.VoiceActivityBlock{padding-top:16px;box-shadow:5px 5px 15px rgba(0,0,0,0.05)}.VoiceActivityBlock .user{position:absolute;margin-top:-16px;left:32px}.VoiceActivityBlock .user .MuiChip-root{padding:8px 32px;border-radius:4px;background-color:#000000;color:#fff}.VoiceActivityBlock .MuiCard-root{padding:0 24px;box-shadow:none}.VoiceActivityBlock .MuiCardHeader-content{margin-top:8px;display:flex;justify-content:space-between}.VoiceActivityBlock .listen{margin-left:32px;border-radius:8px;padding:4px 8px}

.HomePage .MuiGrid-spacing-xs-4{width:100%;margin:0}.HomePage .Sidebox{background-color:#fff;box-shadow:5px 5px 15px rgba(0,0,0,0.05)}.HomePage .TextField,.HomePage .Dropdown{margin:0 auto}.HomePage .active-page-space{background-color:#fff;box-shadow:5px 5px 15px rgba(0,0,0,0.05)}.HomePage .active-page-space.active-page-Activity{background-color:transparent;box-shadow:none;padding:0}.HomePage .CallRecordItem:first-child{margin-top:16px}.HomePage blockquote.comment{quotes:"“" "”" "‘" "’";font-style:italic}.HomePage blockquote.comment::before{content:open-quote}.HomePage blockquote.comment::after{content:close-quote}@media (min-width: 960px){.HomePage .Sidebox{position:-webkit-sticky;position:sticky;top:67px}}@media (min-width: 600px){.social-block-group-w{position:fixed;bottom:0;left:0;width:100%;justify-content:center !important;z-index:1}}@media (max-width: 600px){.HomePage .MuiGrid-spacing-xs-4{width:100%;margin:auto}.HomePage .ActivityPage .action-buttons{justify-content:center}.HomePage .ActivityPage .Header-title{text-transform:capitalize}}

@media (min-width: 991px){
	body:not(.sidebar-mini-md) .unselectable .content-wrapper, body:not(.sidebar-mini-md) .main-footer, body:not(.sidebar-mini-md) .main-header {
	    transition: margin-left .1s ease-in-out;
	    margin-left: 250px;
	}
    
}

.main-sidebar.main-sidebar-mini ~ .unselectable .content-wrapper, .main-sidebar.main-sidebar-mini ~ .main-header, .main-sidebar.main-sidebar-mini ~ .unselectable .main-footer {
    margin-left: 4.6rem!important;
}

/*.content-wrapper-collapsed, .main-footer-collapsed, .main-header-collapsed {
    margin-left: 4.6rem!important;
}*/

@media (min-width: 767px){
    .search-group {
        width: 30rem;
        margin-top: .5rem;
        margin-bottom: .25rem;
    }
}
@media (max-width: 767px){
    .mobile_search {
        /*background-color: #fff;*/
        margin-bottom: 0px!important;
        margin-top: 12.5px!important;
    }

    .main-header .nav-link .search_icon {
        font-size: 37px;
        padding-left:.5rem;
        top: -7px;
        position: relative;
    }
}

.navbar-white{
	background-color:#fff;
}
.main-header {
    border-bottom: 1px solid #dee2e6;
    z-index: 1034;
}
.navbar-light .navbar-nav .nav-link {
    color: rgba(0,0,0,.5);
}

.nav-item a .site_img{
    /* height: 36px; */
    width: 70%;
    float: left;
}

.main-header .nav-link {
    height: 2.5rem;
    position: relative;
}

.dropdown-menu-lg {
    max-width: 300px;
    min-width: 280px!important;
    padding: 0!important;
}

.img-size-50 {
    width: 50px;
}
.img-circle {
    border-radius: 50%;
}
.dropdown-menu-lg .dropdown-item {
    padding: .5rem 1rem;
}
.dropdown-item-title {
    font-size: 1rem;
    margin: 0;
}
.dropdown-menu-lg p {
    margin: 0;
    white-space: normal;
}
.text-sm {
    font-size: .85rem!important;
}
.messageHeader{
    z-index: 2000;
}
.main-header.headerPadding{
    padding: 12.9px;
}
@media only screen and (max-width: 991px){
    .navbar-badge {
        position: absolute;
    }
    .mobileSearch {
        position: relative;
        top: 8px;
    }
    .mobileSearchForm{
        display: none;
    }

    .filterMenu {
        width: 98% !important;
        /*right: 8% !important;*/
        position: fixed;
    }
    
    .main-header.mobile-side-open ~ .main-sidebar{
        margin-left: 0px !important;
    }
    .headerCount{
        background-color: #fff;
    }
}

.filterMenu{
    width: 30%;
    right: 0px;
    top: 7.75em;
    margin-right: -662px;
    position: fixed;
    transition: all 0.8s linear 0s;
    background-color: #fff;
    border: 1px solid #d2d2d2;
    border-radius: .25rem;
}
.filterMenuShow{
    margin-right: 0px!important;
}

.assign_magnet svg{
    font-size: 30px !important;
    margin-right: 10px;
    cursor: pointer;
    right: 1.5em !important;
    top:0.5em !important;
}
.nav-link svg{
    font-size: 22px;
}

  .rectangle {
    width: 160px;
    height: 37px;
    box-shadow: 0px 0px 1px 1px #0000001a;
  }
  
  .pulse {
    -webkit-animation: pulse-animation 2s infinite;
            animation: pulse-animation 2s infinite;
  }
  
  @-webkit-keyframes pulse-animation {
    0% {
      box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
    }
    100% {
      box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
  }
  
  @keyframes pulse-animation {
    0% {
      box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
    }
    100% {
      box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
  }
  @media (max-width:768px){
    .rectangle {
      width: 115px;
    }
  }
  
.small_site_img{
    height: 55px;
    width: 72px!important;
    justify-content: center;
    align-items: center;
    padding: 0.5em!important;
}
.main-sidebar-mini{
    margin-left: 0;
    width: 4.6rem!important;
}
.main-sidebar.main-sidebar-mini:hover {
    width: 250px !important;
}
.main-sidebar{
    bottom: 0;
    float: none;
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
}


.main-sidebar.main-sidebar-mini .nav.nav-pills.nav-sidebar.flex-column > li >a>p, .main-sidebar.main-sidebar-mini .sidebar .info, .main-sidebar.main-sidebar-mini .nav.nav-pills.nav-sidebar.flex-column > li >a>.leftSideIcon{
    -webkit-transform: rotateY(-90deg);
            transform: rotateY(-90deg);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
    transition: all 0.3s ease-in-out;
}

.main-sidebar.main-sidebar-mini:hover .nav.nav-pills.nav-sidebar.flex-column > li >a>p, .main-sidebar.main-sidebar-mini:hover .sidebar .info, .main-sidebar.main-sidebar-mini:hover .nav.nav-pills.nav-sidebar.flex-column > li >a>.leftSideIcon{
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
    opacity: 1;
}

/*.main-sidebar.main-sidebar-mini .nav.nav-pills.nav-sidebar.flex-column > li >a>.leftSideIcon{
    display: none;
}
.main-sidebar.main-sidebar-mini:hover .nav.nav-pills.nav-sidebar.flex-column > li >a>.leftSideIcon{
    display: inline-block;
}*/

.elevation-4 {
    box-shadow: 0 14px 28px rgba(0,0,0,.25),0 10px 10px rgba(0,0,0,.22)!important;
}
[class*=sidebar-dark-] {
    /*background-color: #343a40;*/
    background-color: #000;
}
.main-sidebar {
    height: 100vh;
    overflow-y: hidden;
    z-index: 1038;
}
.main-sidebar, .main-sidebar::before {
    transition: margin-left .3s ease-in-out,width .3s ease-in-out;
    width: 250px;
}
/*.main-sidebar:hover{
    transition: margin-left .3s ease-in-out,width .3s ease-in-out;
    width: 250px!important;
}*/
.subLinkTitle{
    display: flex !important;
    align-items: center;
}

.sublinkName{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 172px;
}


.sidebar {
    color: inherit;
    height: calc(100vh - 75px);
    z-index: 4;
    overflow: auto;
    position: relative;
    overflow-scrolling: touch;
    transition-duration: .2s, .2s, .35s;
    transition-property: top, bottom, width;
    transition-timing-function: linear, linear, ease;
}
.ServiceRm{
    padding: .5rem 1.6rem;
}
.ServiceRm .RmDetail{
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px solid;
    margin-top: 60px;
    padding: 8px;
}
.IssueSupport{
    border: 2px solid;
    margin-top: 60px;
    padding: 8px;
}
.IssueSupport{
    text-align: center;
}
@media(min-width:767px){
    
    .sidebar::-webkit-scrollbar, .MuiTableContainer-root::-webkit-scrollbar {
        width: 8px;
    }

    .sidebar::-webkit-scrollbar-track, .MuiTableContainer-root::-webkit-scrollbar-track { 
        box-shadow: inset 0 0 6px rgba(255,255,255,0.3); 
        border-radius: 10px;
    }

    .sidebar::-webkit-scrollbar-thumb,.MuiTableContainer-root::-webkit-scrollbar-thumb {
        border-radius: 10px; 
        box-shadow: inset 0 0 6px rgba(255,255,255,0.5); 
    }

    .MuiTableContainer-root::-webkit-scrollbar {
        width: 8px;
    }

    .MuiTableContainer-root::-webkit-scrollbar-track { 
        box-shadow: inset 0 0 6px rgba(0,0,0); 
        border-radius: 10px;
    }

    .MuiTableContainer-root::-webkit-scrollbar-thumb {
        border-radius: 10px; 
        box-shadow: inset 0 0 6px rgba(0,0,0,0.8); 
    }

}

[class*=sidebar-dark] .brand-link {
    border-bottom: 1px solid #4b545c;
    color: rgba(255,255,255,.8);
    width: 100%;
}
.brand-link, .brand-link > a > img{
    width: 100%;
    /*padding: 0.3em 0px;*/
    object-fit: contain;
    height: 50px;
}
.user-panel {
    border-bottom: 1px solid #4f5962;
}
.user-panel, .user-panel .info {
    overflow: hidden;
    white-space: nowrap;
}
.user-panel .image {
    display: inline-block;
    padding-left: 1.2rem;
}
/*.user-panel svg {
    height: auto;
    width: 2.1rem;
    font-size: 34px;
    color:#fff;
}*/
.user-panel .userIcon {
    /*height: auto;*/
    /*width: 2.1rem;*/
    font-size: 34px;
    color:#fff;
}

.info .userName{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 145px;
}

.user-panel .info {
    /*cursor: pointer;*/
    display: inline-block;
    padding: 5px 5px 5px 10px;
}
.user-panel, .user-panel .info {
    overflow: hidden;
    white-space: nowrap;
}
.user-panel a, .userName {
	color: #c2c7d0;
}
.nav-sidebar .nav-item>.nav-link {
    position: relative;
}
@media (min-width: 992px){
	.nav-sidebar{
	    white-space: nowrap;
	    overflow: hidden;
	}
}

.nav-sidebar>.nav-item {
    margin-bottom: 0;
}
.nav-sidebar .nav-link p {
    display: inline-block;
    margin: 0;
}
.nav-link p, .user-panel .info {
    transition: margin-left .3s linear,opacity .3s ease,visibility .3s ease;
}
.nav-sidebar>.nav-item .nav-icon {
    font-size: 1.2rem;
    margin-right: .8rem;
    text-align: center;
    /*width: 1.6rem;*/
}
.nav-icon .fas {
	font-size: 1.1rem;
}
.nav-sidebar .nav-link>p>.right {
    position: absolute;
    right: 1rem;
    top: .7rem;
}

.leftSideIcon{
    display: inline-block;
    float: right;
}

.nav-sidebar .nav-link>p>.right:nth-child(2) {
    right: 2.2rem;
}
[class*=sidebar-dark-] .nav-sidebar>.nav-item>.nav-treeview {
    background: 0 0;
}
.nav-sidebar .nav-treeview {
    display: none;
    list-style: none;
    padding: 0;
}
[class*=sidebar-dark-] .nav-treeview>.nav-item>.nav-link {
    color: #c2c7d0;
}
.downAngleIcon{
	display: none;
}
.leftAngleIcon{
	display: block;
}
.nav-link > p >a {
    color:#333;
}

.nav-item .active{
    color:#000!important;
}

@media only screen and (max-width: 991px){

    .main-sidebar-open {
        margin-left: 0px !important;
    }
    
    .main-header.mobile-side-open ~ .main-sidebar{
        margin-left: 0px !important;
    }

    .main-sidebar {
        margin-left: -264px;
        position: fixed;
        height: 100vh !important;
        transition: margin-left .5s ease-in-out;
    }

    li.nav-item.has-treeview {
        width: 100%;
    }

    .close_sideHeader {
        position: absolute;
        right: 6px;
        font-size: 20px;
        top: 18px;
    }
    .sidebar {
        height: calc(100vh - 12px);
    }
}

