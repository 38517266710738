@import "../sass_config/theme.sass";

.NavigationButtonHeaderGroup {
	.button {
		text-transform: uppercase;
		width: get-spacing(15);
		margin-top: get-spacing(1);
		color: get-color('primary', 'contrastText'); } }


.MuiMenu-list {
	background-color: #fff; }
