.addProject svg {
	font-size: 22px;
	cursor: pointer;
}

.ProjectUpdate {
    top: 42px;
}

.ListContent span, .ListContent b{
	font-size: 12px;
}
/* .MainList .ListContent:before {
    content: '';
    width: 5px;
    height: 100%;
    position: absolute;
    background-color: #002984;
    left: -1px;
    top: 0;
} */

.action_box .statusSwitch {
    position: absolute;
    right: 25px;
    top: 3px;
    width: 110px;
}

.action_box .focusSwitch{
    position: absolute;
    right: 0;
    top: 45px;
    width: 133px;
}