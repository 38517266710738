.close_form {
    position: absolute;
    cursor: pointer;
    top: 12px;
    right: 20px;
}
.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 0px 65px 0px 0px !important; 
}
.MuiInputLabel-formControl {
    top: -8px !important;
}
.projectForm textarea, .project_name .MuiAutocomplete-hasPopupIcon{
	width: 100%;
}
.showFildes{
	text-decoration: underline;
	cursor: pointer;
	font-size: 12px;
}
.MuiFormControl-marginNormal{
    margin-top: 0px !important;
}
.MuiSelect-selectMenu,.MuiListItem-gutters {
    text-transform: capitalize;
}
.MuiAutocomplete-popper li {
    text-transform: capitalize;
}

.bulk_file a {
    text-decoration: underline;
    color: #6b5c5a;
}

.bulk_file svg {
    font-size: 20px;
}

.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover{
    background-color: rgba(0, 0, 0, 0.13) !important;
}